import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import crud from "../../api/crud";

import { queryKeys } from "../../constants/request";

import { routes } from "../../router/routes";

import {
	APIError,
	RejectWithValue,
	ResponseData,
	ResponseDataWithPaging,
	getAllParams,
	getByIDParams,
	getCustomResourceParams
} from "../../types/api";
import { Test } from "../../types/test";

import {
	appendSearchQuery,
	createQuery,
	createRoute,
	getOffset,
	paginationFallback,
	setQueryParam
} from "../../utils/request";
import {
	multiValueFilterQuery,
	replaceQueryQuestionmarkWithAmpersand
} from "../../utils/method";

interface ITestReducer {
	testData: ResponseDataWithPaging | ResponseData | null;
	isFetching: boolean;
	errorMessage: APIError | string | null;
}

const initialState: ITestReducer = {
	testData: null,
	isFetching: false,
	errorMessage: null
};

const {
	ID,
	FILTER: {
		TEST: {
			NAME,
			PROJECT_ID,
			TEST_MODE,
			INCREMENT_STRATEGY,
			START_INTERVAL_FROM,
			START_INTERVAL_TO,
			PARTICIPANT_TIMEOUT_FROM,
			PARTICIPANT_TIMEOUT_TO
		}
	},
	LIMIT,
	OFFSET,
	PAGE
} = queryKeys;

export const getAllTests = createAsyncThunk(
	"tests/getAllTests",
	async (
		params: getAllParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, queryParams } = params;
		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const {
			name,
			project_id,
			testMode,
			incrementStrategy,
			startIntervalFrom,
			startIntervalTo,
			participantTimeoutFrom,
			participantTimeoutTo
		} = filters || {};

		let searchQuery = createQuery(
			setQueryParam(START_INTERVAL_FROM, startIntervalFrom),
			setQueryParam(START_INTERVAL_TO, startIntervalTo),
			setQueryParam(PARTICIPANT_TIMEOUT_FROM, participantTimeoutFrom),
			setQueryParam(PARTICIPANT_TIMEOUT_TO, participantTimeoutTo),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(PAGE, page),
			...multiValueFilterQuery(TEST_MODE, testMode),
			...multiValueFilterQuery(INCREMENT_STRATEGY, incrementStrategy)
		);

		searchQuery = replaceQueryQuestionmarkWithAmpersand(searchQuery);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(true, routes.V1ADMIN, routes.TESTS);

		route += createQuery(
			setQueryParam(PROJECT_ID, project_id),
			setQueryParam(START_INTERVAL_FROM, startIntervalFrom),
			setQueryParam(START_INTERVAL_TO, startIntervalTo),
			setQueryParam(PARTICIPANT_TIMEOUT_FROM, participantTimeoutFrom),
			setQueryParam(PARTICIPANT_TIMEOUT_TO, participantTimeoutTo),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(OFFSET, getOffset(limit, page || 1)),
			...multiValueFilterQuery(TEST_MODE, testMode),
			...multiValueFilterQuery(INCREMENT_STRATEGY, incrementStrategy)
		);

		route = replaceQueryQuestionmarkWithAmpersand(route);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllTests
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getTest = createAsyncThunk(
	"tests/getTest",
	async (params: getByIDParams, { rejectWithValue }) => {
		const { navigate, location, id } = params;

		const searchQuery = createQuery(
			setQueryParam(ID, Number(id)),
			setQueryParam(queryKeys.PAGE, 1),
			setQueryParam(queryKeys.LIMIT, 20)
		);
		appendSearchQuery(navigate, location, searchQuery);

		const route = createRoute(true, routes.V1ADMIN, routes.TESTS, id);
		const options = { suppressNotification: true };

		try {
			const response = await crud.READ<Test>(navigate, route, options);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getProjectTests = createAsyncThunk(
	"tests/getProjectTests",
	async (
		params: getCustomResourceParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, id, queryParams } = params;
		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const { name } = filters || {};

		const searchQuery = createQuery(
			setQueryParam(PROJECT_ID, id),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(PAGE, page)
		);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(
			true,
			routes.V1ADMIN,
			routes.PROJECTS,
			id,
			routes.TESTS
		);

		route += createQuery(
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(OFFSET, getOffset(limit, page || 1))
		);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getProjectTests
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const testsSlice = createSlice({
	name: "tests",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAllTests.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getAllTests.fulfilled, (state, action) => {
				state.testData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getAllTests.rejected, (state, action) => {
				state.testData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getTest.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getTest.fulfilled, (state, action) => {
				state.testData = { results: [action.payload as Test] };
				state.isFetching = false;
			})
			.addCase(getTest.rejected, (state, action) => {
				state.testData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getProjectTests.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getProjectTests.fulfilled, (state, action) => {
				state.testData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getProjectTests.rejected, (state, action) => {
				state.testData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			});
	}
});

export default testsSlice.reducer;
