import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import media from "../../assets/media/media";

import { config } from "../../config/config";

import { AppDispatch } from "../../redux/store";
import { logoutUser } from "../../redux/slices/auth";

import { routes } from "../../router/routes";

import { deleteSession } from "../../utils/session";

const Header = (): JSX.Element => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const handleLogout = (): void => {
		dispatch(logoutUser(navigate));
		deleteSession();
	};

	return (
		<div className="header">
			<Link to={routes.HOME} className="header__logo">
				<img src={media.loadero.header} alt="Loadero" />
			</Link>

			<div className="header__nav">
				<a
					className="link"
					rel="noopener noreferrer"
					target="_blank"
					href={config.siteUrl}
				>
					Open site
				</a>

				<div className="header__separator" />

				<button
					className="link link--button"
					onClick={handleLogout}
					type="button"
				>
					Log out
				</button>
			</div>
		</div>
	);
};

export default Header;
