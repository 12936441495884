import { NavigateFunction } from "react-router-dom";

import { authRest } from "../api/config";

import { authKeys, scopes } from "../constants";

import {
	removeValueFromStorage,
	setValueToStorage
} from "../redux/slices/persistedStorage";
import { AppDispatch, store as reduxStore } from "../redux/store";

import { routes } from "../router/routes";

import { AuthResponse } from "../types/api";

import { arraysEqual } from "./method";

import { createRoute } from "./request";

const dispatch: AppDispatch = reduxStore.dispatch;

const deleteSession = (): void => {
	Object.keys(authKeys).forEach(key => {
		dispatch(removeValueFromStorage(authKeys[key]));
	});
};

const setSession = (authResult: AuthResponse): void => {
	const expiresAt = JSON.stringify(
		authResult.expires_in * 1000 + new Date().getTime()
	);

	const { ACCESS_TOKEN, EXPIRES_AT, TOKEN_TYPE, REFRESH_TOKEN, SCOPES } =
		authKeys;
	const { access_token, refresh_token, token_type } = authResult;

	dispatch(setValueToStorage({ name: ACCESS_TOKEN, value: access_token }));
	dispatch(setValueToStorage({ name: REFRESH_TOKEN, value: refresh_token }));
	dispatch(setValueToStorage({ name: EXPIRES_AT, value: expiresAt }));
	dispatch(setValueToStorage({ name: TOKEN_TYPE, value: token_type }));
	dispatch(setValueToStorage({ name: SCOPES, value: authResult.scopes }));
};

const refreshToken = (
	navigate: NavigateFunction,
	retryAttempt = 0
): Promise<void> => {
	const route = createRoute(true, routes.AUTH_TOKEN);

	const { refresh_token_pending, refresh_token_resolved, scopes } =
		reduxStore.getState().persistedStorage;

	const data = new FormData();
	return new Promise<void>((resolve, reject) => {
		if (refresh_token_pending === true) {
			if (refresh_token_resolved === false) {
				if (retryAttempt >= 4) {
					return reject();
				}

				return setTimeout(async () => {
					await refreshToken(navigate, retryAttempt + 1).then(
						() => {
							return resolve();
						},
						() => {
							deleteSession();

							return reject();
						}
					);
				}, 1500);
			}

			return resolve();
		}

		if (!scopes || scopes.length === 0) {
			deleteSession();

			return reject();
		}

		data.append(authKeys.GRANT_TYPE, authKeys.REFRESH_TOKEN);
		data.append(
			authKeys.REFRESH_TOKEN,
			reduxStore.getState().persistedStorage.refresh_token
		);
		data.append(authKeys.SCOPE, scopes.join(" "));

		dispatch(
			setValueToStorage({ name: authKeys.REFRESH_TOKEN_PENDING, value: true })
		);
		dispatch(
			setValueToStorage({ name: authKeys.REFRESH_TOKEN_RESOLVED, value: false })
		);

		authRest
			.post(route, data)
			.then(
				response => {
					if (arraysEqual(response.data.scopes, scopes)) {
						setSession(response.data);

						return resolve();
					}

					if (navigate) navigate(routes.LOG_OUT);

					reject();
				},
				() => {
					deleteSession();
					reject();
				}
			)
			.then(() => {
				dispatch(
					setValueToStorage({
						name: authKeys.REFRESH_TOKEN_PENDING,
						value: false
					})
				);
				dispatch(
					setValueToStorage({
						name: authKeys.REFRESH_TOKEN_RESOLVED,
						value: true
					})
				);
			});
	});
};

const validSession = (navigate: NavigateFunction): Promise<boolean> => {
	let expiresAt = parseInt(
		reduxStore.getState().persistedStorage.expires_at || "0",
		10
	);

	return new Promise(resolve => {
		if (new Date().getTime() > expiresAt) {
			refreshToken(navigate).then(
				() => {
					expiresAt = parseInt(
						reduxStore.getState().persistedStorage.expires_at || "0",
						10
					);
					resolve(new Date().getTime() < expiresAt);
				},
				() => {
					resolve(false);
				}
			);
		} else {
			resolve(true);
		}
	});
};

const checkAuthStatus = (navigate: NavigateFunction): Promise<void> =>
	new Promise((resolve, reject) => {
		validSession(navigate).then(authState => {
			const authScopes = reduxStore.getState().persistedStorage.scopes;

			if (
				authState &&
				Array.isArray(authScopes) &&
				arraysEqual(scopes, authScopes)
			) {
				resolve();
				return;
			}

			if (navigate) navigate(routes.LOG_OUT);

			reject();
		});
	});

export { checkAuthStatus, deleteSession, setSession, validSession };
