import { createSlice } from "@reduxjs/toolkit";

import { StorageKey } from "../../types/persistedStorage";

type Value = string | string[] | boolean;
type Payload = {
	name: StorageKey;
	value: Value;
};

interface IPersistedStorageReducer {
	access_token: string;
	code_verifier: string;
	cookies: string;
	expires_at: string;
	loadero_superuser?: string;
	refresh_token: string;
	refresh_token_pending?: boolean | string;
	refresh_token_resolved?: boolean | string;
	scopes: string[];
}

const initialState: IPersistedStorageReducer = {
	access_token: "",
	code_verifier: "",
	cookies: "",
	expires_at: "",
	refresh_token: "",
	scopes: []
};

const persistedStorageSlice = createSlice({
	name: "persistedStorage",
	initialState,
	reducers: {
		setValueToStorage: (state, action: { payload: Payload }) => {
			const { name, value } = action.payload;
			(state[name as keyof IPersistedStorageReducer] as Value) = value as Value;
		},
		removeValueFromStorage: (state, action: { payload: StorageKey }) => {
			delete state[action.payload as keyof IPersistedStorageReducer];
		}
	}
});

export const { removeValueFromStorage, setValueToStorage } =
	persistedStorageSlice.actions;
export default persistedStorageSlice.reducer;
