import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import qs from "query-string";

import AutoFillTable from "../../components/autoFillTable/AutoFillTable";

import { columnNames, queryKeys, tableName } from "../../constants";

import { useAutoFillTableLogic } from "../../hooks/useAutoFillTableLogic";

import {
	getAccountProjects,
	getAllProjects,
	getProject,
	getSubscriptionProjects
} from "../../redux/slices/projects";
import { IStoreState } from "../../redux/slices";

import { routes } from "../../router/routes";

import { ConfigFilters } from "../../types/filters";

import { createQuery, createRoute, setQueryParam } from "../../utils/request";

const Projects: FC = (): JSX.Element => {
	const { errorMessage, isFetching, projectData } = useSelector(
		(state: IStoreState) => state.projectReducer
	);

	const {
		ACTIVATION_DATE,
		PAYMENT_PLAN,
		PAYMENT_STATUS,
		PLAN_LIMITS,
		SUBSCRIPTION,
		ACCOUNT_ROLE,
		TESTS,
		TRIAL_EXPIRED,
		SUBSCRIPTION_ID
	} = columnNames;

	const configFilters = [
		{
			name: "id",
			elementType: "input"
		},
		{
			name: "name",
			elementType: "input"
		},
		{
			name: "language",
			elementType: "input"
		},

		{
			name: "hasSubscription",
			elementType: "radio",
			label: "Has subscription",
			options: [
				{ label: "Yes", key: "true" },
				{ label: "No", key: "false" },
				{ label: "All", key: "" }
			],
			selected: ""
		},
		{
			name: "hasAwsInfo",
			elementType: "radio",
			label: "Has custom AWS",
			options: [
				{ label: "Yes", key: "true" },
				{ label: "No", key: "false" },
				{ label: "All", key: "" }
			],
			selected: ""
		}
	];

	const accountID = qs.parse(location.search).account_id;
	const allAccountsRoute = createRoute(true, routes.ACCOUNTS);

	let accountRoute = createRoute(true, routes.ACCOUNTS);
	accountRoute += createQuery(setQueryParam(queryKeys.ID, accountID));

	const customColumns = [SUBSCRIPTION_ID];
	const disabledColumns = [SUBSCRIPTION_ID, ACTIVATION_DATE];
	const nestedData = {
		key: SUBSCRIPTION,
		columns: [ACTIVATION_DATE, PAYMENT_PLAN, PAYMENT_STATUS]
	};
	const suppressColumns = [
		ACCOUNT_ROLE,
		PLAN_LIMITS,
		SUBSCRIPTION,
		TRIAL_EXPIRED
	];
	const testsRoute = createRoute(true, routes.TESTS);
	const subsRoute = createRoute(true, routes.SUBSCRIPTIONS);

	const linkColumns = [
		{ name: TESTS, route: testsRoute },
		{ name: SUBSCRIPTION_ID, route: subsRoute }
	];

	const qpResolver = [
		{ paramMatch: "account_id", fn: getAccountProjects },
		{ paramMatch: "subscription_id", fn: getSubscriptionProjects }
	];

	const {
		columnFilters,
		colNames,
		handleFetchData,
		init,
		onPageChange,
		pager,
		setColumnFilters,
		setDataFilters
	} = useAutoFillTableLogic(
		customColumns,
		disabledColumns,
		getAllProjects,
		getProject,
		nestedData,
		suppressColumns,
		tableName.PROJECTS,
		qpResolver,
		linkColumns
	);

	return (
		<div className="projects">
			<div className="projects__account">
				<h1>Projects</h1>

				{accountID && (
					<span className="link">
						{errorMessage ? (
							<Link to={allAccountsRoute}>{"Back to accounts"}</Link>
						) : (
							<Link to={accountRoute}>{`Account ID ${accountID}`}</Link>
						)}
					</span>
				)}
			</div>

			<AutoFillTable
				columnFilters={columnFilters}
				tableColumnNames={colNames}
				data={projectData}
				filters={configFilters as ConfigFilters[]}
				handleFetchData={handleFetchData}
				init={init}
				loading={isFetching}
				nestedData={nestedData}
				onPageChange={onPageChange}
				pager={pager}
				setColumnFilters={setColumnFilters}
				setDataFilters={setDataFilters}
				tableName={tableName.PROJECTS}
				linkColumns={linkColumns}
			/>
		</div>
	);
};

export default Projects;
