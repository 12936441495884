import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import moment from "moment";

import {
	Button,
	CheckBoxState,
	DatePicker,
	Input,
	Option,
	RadioGroup,
	SVGIcon
} from "ui-kit";
import { MultiSelectDropdown } from "ui-kit/src/components/dropdowns";

import { uiKitTranslations } from "../../config/config";

import { keyCodes } from "../../constants";

import { ConfigFilters, AppliedFilters } from "../../types/filters";
import { PagerData } from "../../types/pagination";

import {
	arrayNotEmpty,
	formatObjectKey,
	initializeStateFromFilters,
	initializeStateFromQParams,
	objectEqual
} from "../../utils/method";
import { getStateFromQuery } from "../../utils/request";

interface IProps {
	filters: ConfigFilters[];
	applyFilters(pager: PagerData | null, filters: AppliedFilters): void;
	onFilterChange(filterState: AppliedFilters): void;
}

const DataFilter: FC<IProps> = ({
	filters,
	applyFilters,
	onFilterChange
}): JSX.Element => {
	const location = useLocation();

	const queryResults = getStateFromQuery(location);

	const [filterState, setFilterState] = useState<AppliedFilters>(
		initializeStateFromQParams(filters, queryResults)
	);

	const handleSetDate = (fieldName: string, dTo: Date | null): void => {
		let newDate = "";

		if (dTo && dTo?.getTime() !== new Date().setHours(23, 59, 59, 999)) {
			newDate = moment(dTo).format("YYYY-MM-DD");
		}

		onChange(fieldName, newDate);
	};

	const clearAllFilters = (): void => {
		const initialFilterState = initializeStateFromFilters(filters);

		onFilterChange(initialFilterState);
		applyFilters(null, initialFilterState);
	};

	const onChange = (fieldName: string, value: string): void => {
		const updatedFilters = { ...filterState, [fieldName]: value };

		if (!objectEqual(updatedFilters, filterState)) {
			setFilterState(updatedFilters);
		}
	};

	const onMultiChange = (name: string, value: CheckBoxState): void => {
		const updatedFilters = { ...filterState, [name]: value };

		if (!objectEqual(updatedFilters, filterState)) {
			setFilterState(updatedFilters);
		}
	};

	const handleFilter = (): void => {
		const prevFilterState = initializeStateFromQParams(filters, queryResults);

		if (!objectEqual(prevFilterState, filterState)) {
			applyFilters(null, filterState);
		}
	};

	const clearSearchQuery = (name: string): void => {
		const updatedFilters = { ...filterState, [name]: "" };

		setFilterState(updatedFilters);
		onFilterChange(updatedFilters);
	};

	const shouldDisableIDFilter = (): boolean => {
		const filtersWithoutID = { ...filterState };
		delete filtersWithoutID.id;

		return Object.values(filtersWithoutID).some(
			value => value !== "" && Object.values(value).some(v => v)
		);
	};

	const disableFilter = (name: string): boolean => {
		if (name === "id") {
			return shouldDisableIDFilter();
		}

		return filterState.id !== "";
	};

	const renderElement = ({
		elementType,
		name,
		label,
		options
	}: ConfigFilters): JSX.Element | null => {
		switch (elementType) {
			case "input":
				return (
					<div className="data-filter__search" key={name}>
						<Input
							className="input--data-filter"
							onChange={onChange}
							inputName={name}
							type="text"
							size="sm"
							label={formatObjectKey(name)}
							inputValue={
								filterState[name as keyof typeof filterState] as string
							}
							key={name}
							disabled={disableFilter(name)}
						/>

						{filterState[name as keyof typeof filterState] !== "" && (
							<SVGIcon
								className="clear-field-icon"
								iconName="close"
								onClick={(): void => clearSearchQuery(name)}
								width={12}
							/>
						)}
					</div>
				);
			case "radio":
				const items = options.map(({ label, key }: Option) => ({
					name,
					label,
					value: key as string,
					disabled: disableFilter(label)
				}));

				return (
					<div className="data-filter__radio" key={name}>
						<div className="data-filter__custom-label">{label}</div>

						<div className="data-filter__radio-group">
							<RadioGroup
								activeValue={filterState[
									name as keyof typeof filterState
								].toString()}
								items={items}
								onChange={onChange}
							/>
						</div>
					</div>
				);
			case "date":
				const invalidDate = moment.invalid().toDate().toString();
				let filterDate: string | Date = moment.invalid().toDate().toString();

				if (typeof filterState[name as keyof typeof filterState] === "string") {
					filterDate = moment(
						filterState[name as keyof typeof filterState] as string,
						"YYYY-MM-DD"
					).toDate();
				}

				const date = filterDate.toString() === invalidDate ? null : filterDate;

				return (
					<div className="date-wrapper" key={name}>
						<div className="data-filter__date-wrapper">
							<b className="data-filter__custom-label">
								{formatObjectKey(name)}
							</b>

							<div className="data-filter__date-wrapper">
								<DatePicker
									to={date as Date}
									onChange={(_, dTo): void => {
										handleSetDate(name, dTo);
									}}
									t={uiKitTranslations}
									single
									boundaries={{
										start: new Date("2018-09-01"),
										end: new Date()
									}}
								/>
							</div>
						</div>
					</div>
				);

			case "multiselect":
				const multiOptions = options.map(({ label, key }: Option) => ({
					key,
					label,
					disabled: disableFilter(label)
				}));

				return (
					<div className="data-filter__multiselect" key={name}>
						<div className="data-filter__custom-label">{label}</div>

						<MultiSelectDropdown
							name={name}
							onChange={onMultiChange}
							t={uiKitTranslations}
							options={multiOptions}
							value={filterState[name] as CheckBoxState}
						/>
					</div>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent): void => {
			if (event.keyCode === keyCodes.ENTER) {
				handleFilter();
			}
		};
		document.addEventListener("keydown", handleKeyDown);

		return (): void => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleFilter]);

	return (
		<div className="data-filter">
			{filters.map((f: ConfigFilters) => renderElement(f))}

			{arrayNotEmpty(filters) && (
				<div className="data-filter__buttons">
					<Button brand="secondary" onClick={clearAllFilters}>
						Clear all
					</Button>

					<Button onClick={handleFilter}>Apply</Button>
				</div>
			)}
		</div>
	);
};

export default DataFilter;
