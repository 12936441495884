import { FC, Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ExtendedPagination, Loader } from "ui-kit";
import { classNames } from "ui-kit/src/utils/methods";

import { uiKitTranslations } from "../../config/config";
import {
	columnNames,
	tableName as tableNames,
	queryKeys
} from "../../constants";

import { Account } from "../../types/account";
import { ResponseData, ResponseDataWithPaging } from "../../types/api";
import {
	AppliedFilters,
	ColumnFilters,
	ConfigFilters
} from "../../types/filters";
import { PagerData } from "../../types/pagination";
import { Project } from "../../types/project";

import { formatObjectKey, formatTableValue } from "../../utils/method";
import { createQuery, setQueryParam } from "../../utils/request";

import ColumnFilter from "./ColumnFilter";
import DataFilter from "./DataFilter";

/**
 * Data transfer object
 */
type DTO = Account | Project;

/**
 * A column that renders data as Router links
 */
type LinkColumn = {
	name: string;
	route: string;
};

/**
 * Describes data that needs to be
 * dynamically rendered from a nested object
 */
type NestedData = {
	key: string;
	columns: string[];
};

interface IProps {
	columnFilters: ColumnFilters;
	tableColumnNames: string[];
	data: ResponseData | ResponseDataWithPaging | null;
	filters: ConfigFilters[];
	handleFetchData: (pagination: PagerData, dFilters: AppliedFilters) => void;
	init: boolean;
	linkColumns?: LinkColumn[];
	loading: boolean;
	nestedData?: NestedData;
	onPageChange: (currentPage: number, itemsPerPage: number) => void;
	pager: PagerData;
	setColumnFilters: (cf: ColumnFilters) => void;
	setDataFilters: (af: AppliedFilters) => void;
	tableName: string;
}

const AutoFillTable: FC<IProps> = ({
	columnFilters,
	data,
	filters,
	handleFetchData,
	init,
	linkColumns,
	loading,
	nestedData,
	onPageChange,
	pager,
	setColumnFilters,
	setDataFilters,
	tableColumnNames,
	tableName
}) => {
	const {
		GROUP_COUNT,
		GROUP_ID,
		PARTICIPANT_COUNT,
		RUNS,
		TEST_ID,
		TESTS,
		PROJECTS
	} = columnNames;
	const [dropdownActive, setDropdownActive] = useState<boolean>(false);

	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

	const hasResults = (): boolean => {
		const length = data?.results?.length;

		return typeof length === "number" ? length > 0 : false;
	};

	const hasColumns = (): boolean => {
		const length = tableColumnNames?.length;

		return typeof length === "number" ? length > 0 : false;
	};

	const toggleDropdown = (): void => {
		setDropdownActive(!dropdownActive);
	};

	const renderHeaderCell = (value: string, mapKey: string): JSX.Element => (
		<th key={mapKey}>{formatObjectKey(value)}</th>
	);

	const cellData = (children: string): JSX.Element[] => {
		return formatTableValue(children || "-")
			?.split("\n")
			.map((cellRow, index) => (
				<Fragment key={index}>
					{cellRow}
					{index !== cellRow.length - 1 && <br />}
				</Fragment>
			));
	};

	const renderCell = (children: string, mapKey: string): JSX.Element => (
		<td key={mapKey}>{cellData(children)}</td>
	);

	const renderLinkCell = (
		row: DTO,
		route: string,
		columnName: string
	): JSX.Element => {
		const {
			FILTER: {
				PROJECT: { ACCOUNT_ID, SUBSCRIPTION_ID },
				TEST: { PROJECT_ID }
			},
			LIMIT,
			PAGE,
			ID
		} = queryKeys;

		let idParam;
		let entityID: string | number;
		let cellText: string | number = "View";

		switch (columnName) {
			case GROUP_ID:
				entityID = row["group_id" as keyof DTO];
				cellText = row["group_id" as keyof DTO];
				idParam = ID;
				break;
			case TEST_ID:
				entityID = row["test_id" as keyof DTO];
				cellText = row["test_id" as keyof DTO];
				idParam = ID;
				break;
			case PROJECT_ID:
				entityID = row["project_id" as keyof DTO];
				cellText = row["project_id" as keyof DTO];
				idParam = ID;
				break;
			case SUBSCRIPTION_ID:
				entityID = row["subscription_id" as keyof DTO];
				cellText = row["subscription_id" as keyof DTO];
				idParam = ID;
				break;
			case GROUP_COUNT:
				entityID = row["id" as keyof DTO];
				cellText = row["group_count" as keyof DTO];
				idParam = TEST_ID;
				break;
			case PARTICIPANT_COUNT:
				entityID = row["id" as keyof DTO];
				cellText = row["participant_count" as keyof DTO];
				idParam = tableName === tableNames.GROUPS ? GROUP_ID : TEST_ID;
				break;
			case PROJECTS:
				entityID = row["id" as keyof DTO];
				idParam =
					tableName === tableNames.SUBSCRIPTIONS ? SUBSCRIPTION_ID : ACCOUNT_ID;
				break;
			case RUNS:
				entityID = row["id" as keyof DTO];
				idParam = TEST_ID;
				break;
			case TESTS:
				entityID = row["id" as keyof DTO];
				idParam = PROJECT_ID;
				break;
			default:
				entityID = row["id" as keyof DTO];
				idParam = ACCOUNT_ID;
				break;
		}

		route += createQuery(
			setQueryParam(idParam, entityID),
			setQueryParam(LIMIT, pager.limit),
			setQueryParam(PAGE, 0)
		);

		return (
			<td
				key={entityID + Math.random().toString()}
				className={classNames([cellText && "link"])}
			>
				{cellText ? <Link to={route}>{cellText}</Link> : "-"}
			</td>
		);
	};

	const renderRow = (
		rowData: DTO,
		columnName: string,
		index: number
	): JSX.Element => {
		const linkColumn = linkColumns?.find(c => c.name === columnName);

		switch (true) {
			case !!linkColumn:
				return renderLinkCell(rowData, linkColumn?.route || "", columnName);
			case nestedData?.columns?.includes(columnName):
				const nestedObject = rowData[nestedData?.key as keyof typeof rowData];

				return renderCell(
					!nestedObject ? "-" : nestedObject[columnName as keyof unknown],
					`tr${index}__td${columnName}`
				);
			default:
				return renderCell(
					rowData[columnName as keyof DTO] as string,
					`tr${index}__td${columnName}`
				);
		}
	};

	const renderMessage = (): JSX.Element | null => {
		if (hasResults() && hasColumns()) return null;

		return (
			<p className="auto-fill-table__info-message">No results to display</p>
		);
	};

	useEffect(() => {
		const resizeTable = (): void => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", resizeTable);

		return (): void => window.removeEventListener("resize", resizeTable);
	}, [windowWidth]);

	if (loading || !init) {
		return <Loader centered />;
	}

	return (
		<div className="auto-fill-table">
			<div className="auto-fill-table__left">
				{hasResults() && (
					<div className="auto-fill-table__controls">
						<ColumnFilter
							dropDownActive={dropdownActive}
							toggleDropdown={toggleDropdown}
							filters={columnFilters}
							setFilters={setColumnFilters}
							tableName={tableName}
						/>
					</div>
				)}

				{renderMessage()}

				{hasResults() && hasColumns() && (
					<div
						className={`auto-fill-table__container${
							windowWidth < 1700 ? " auto-fill-table__container--scroll" : ""
						}`}
					>
						<table>
							<thead>
								<tr>
									{tableColumnNames.map((value, index) =>
										renderHeaderCell(value, `${value}_${index}`)
									)}
								</tr>
							</thead>

							<tbody>
								{(data?.results as DTO[])?.map(
									(row, index) =>
										row && (
											<tr key={`tr${index}`}>
												{tableColumnNames.map((name: string, index) =>
													renderRow(row, name, index)
												)}
											</tr>
										)
								)}
							</tbody>
						</table>
					</div>
				)}

				{hasResults() && pager.total_pages > 1 && hasColumns() && (
					<ExtendedPagination
						currentPage={pager.page}
						totalItems={pager.total_items}
						onChange={onPageChange}
						itemsPerPage={pager.limit}
						t={uiKitTranslations}
						options={[
							{ key: 20, label: "20" },
							{ key: 50, label: "50" },
							{ key: 100, label: "100" },
							{ key: 250, label: "250" }
						]}
					/>
				)}
			</div>

			<div className="auto-fill-table__right">
				<div className="auto-fill-table__side-controls">
					<DataFilter
						filters={filters}
						applyFilters={handleFetchData}
						onFilterChange={setDataFilters}
					/>
				</div>
			</div>
		</div>
	);
};

export default AutoFillTable;
