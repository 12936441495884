export const icons = {
	"alert-close": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			viewBox="0 0 12 12"
		>
			<path
				fill="#FFFFFF"
				fillOpacity=".8"
				fillRule="evenodd"
				d="M7.87 6l3.743 3.742a1.323 1.323 0 0 1-1.871 1.87L6 7.872l-3.742 3.742a1.323 1.323 0 0 1-1.87-1.871L4.128 6 .387 2.258A1.323 1.323 0 0 1 2.258.388L6 4.128 9.742.387a1.323 1.323 0 0 1 1.87 1.871L7.872 6z"
			/>
		</svg>
	),
	calendar: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="16"
			viewBox="0 0 17 16"
		>
			<path
				fill="#D8D9E3"
				fillRule="evenodd"
				d="M14.876 2h-2.125V1c0-.553-.477-1-1.064-1-.586 0-1.062.447-1.062 1v1h-4.25V1c0-.553-.475-1-1.062-1C4.726 0 4.25.447 4.25 1v1H2.125C.952 2 0 2.896 0 4v10c0 1.104.952 2 2.125 2h12.75C16.048 16 17 15.104 17 14V4c0-1.105-.951-2-2.124-2zm0 12H2.125V5.5h12.75l.001 8.5z"
			/>
		</svg>
	),
	close: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				stroke="#262630"
				strokeLinecap="round"
				strokeWidth="3"
				d="M4 4l16 16m0-16L4 20"
			/>
		</svg>
	),
	warning: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="#DF203B"
				fillRule="evenodd"
				d="M7.106 3.789l-4.382 8.764c-.333.665.15 1.447.894 1.447h8.764c.743 0 1.227-.782.894-1.447L8.894 3.789c-.368-.737-1.42-.737-1.788 0zM7.5 6v5h1V6h-1zm0 6v1h1v-1h-1z"
				clipRule="evenodd"
			/>
		</svg>
	)
};
