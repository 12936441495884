import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/browser";

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Loader } from "ui-kit";

import App from "./App";
import { config } from "./config/config";

import { store } from "./redux/store";

const persistor = persistStore(store);

import "normalize.css";
import "./index.scss";
import "ui-kit/index.scss";

if (process.env.REACT_APP_ENABLE_SENTRY === "true") {
	Sentry.init({
		dsn: config.sentryKey,
		environment: config.env,
		release: process.env.REACT_APP_VERSION,
		normalizeDepth: 6,
		tracesSampler: () =>
			Number(process.env.REACT_APP_PUBLIC_TRACE_SAMPLER) || 0,
		integrations: [
			new Sentry.Integrations.Breadcrumbs({
				console: false
			})
		]
	});
}

const app = (
	<Provider store={store}>
		<PersistGate loading={<Loader centered />} persistor={persistor}>
			<Router>
				<App />
			</Router>
		</PersistGate>
	</Provider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(app);
