import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import crud from "../../api/crud";

import { queryKeys } from "../../constants";

import { routes } from "../../router/routes";

import {
	APIError,
	RejectWithValue,
	ResponseData,
	ResponseDataWithPaging,
	getAllParams,
	getByIDParams,
	getCustomResourceParams
} from "../../types/api";
import { Participant } from "../../types/participants";

import {
	appendSearchQuery,
	createQuery,
	createRoute,
	getOffset,
	paginationFallback,
	setQueryParam
} from "../../utils/request";
import {
	multiValueFilterQuery,
	replaceQueryQuestionmarkWithAmpersand
} from "../../utils/method";

interface IParticipantReducer {
	participantData: ResponseDataWithPaging | ResponseData | null;
	isFetching: boolean;
	errorMessage: APIError | string | null;
}

const initialState: IParticipantReducer = {
	participantData: null,
	isFetching: false,
	errorMessage: null
};

const {
	FILTER: {
		PARTICIPANTS: {
			AUDIO_FEED,
			BROWSER,
			CU,
			COUNT_FROM,
			COUNT_TO,
			HAS_GROUP,
			NAME,
			NETWORK,
			LOCATION,
			RECORD_AUDIO,
			VIDEO_FEED
		}
	},
	ID,
	LIMIT,
	OFFSET,
	PAGE
} = queryKeys;

export const getAllParticipants = createAsyncThunk(
	"participants/getAllParticipants",
	async (
		params: getAllParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, queryParams } = params;

		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};

		const {
			name,
			countFrom,
			countTo,
			computeUnit,
			hasGroup,
			recordAudio,
			browser,
			network,
			location: filterLocation,
			videoFeed,
			audioFeed
		} = filters || {};

		let searchQuery = createQuery(
			setQueryParam(NAME, name),
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(HAS_GROUP, hasGroup),
			setQueryParam(RECORD_AUDIO, recordAudio),
			setQueryParam(LIMIT, limit),
			setQueryParam(PAGE, page),
			...multiValueFilterQuery(BROWSER, browser),
			...multiValueFilterQuery(CU, computeUnit),
			...multiValueFilterQuery(NETWORK, network),
			...multiValueFilterQuery(LOCATION, filterLocation),
			...multiValueFilterQuery(VIDEO_FEED, videoFeed),
			...multiValueFilterQuery(AUDIO_FEED, audioFeed)
		);

		searchQuery = replaceQueryQuestionmarkWithAmpersand(searchQuery);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(true, routes.V1ADMIN, routes.PARTICIPANTS);

		route += createQuery(
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(HAS_GROUP, hasGroup),
			setQueryParam(RECORD_AUDIO, recordAudio),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(OFFSET, getOffset(limit, page || 1)),
			...multiValueFilterQuery(BROWSER, browser),
			...multiValueFilterQuery(CU, computeUnit),
			...multiValueFilterQuery(NETWORK, network),
			...multiValueFilterQuery(LOCATION, filterLocation),
			...multiValueFilterQuery(VIDEO_FEED, videoFeed),
			...multiValueFilterQuery(AUDIO_FEED, audioFeed)
		);

		route = replaceQueryQuestionmarkWithAmpersand(route);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllParticipants
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getParticipant = createAsyncThunk(
	"participants/getParticipant",
	async (
		params: getByIDParams,
		{ rejectWithValue }
	): Promise<Participant | RejectWithValue> => {
		const { navigate, location, id } = params;

		const searchQuery = createQuery(
			setQueryParam(ID, Number(id)),
			setQueryParam(queryKeys.PAGE, 1),
			setQueryParam(queryKeys.LIMIT, 20)
		);

		appendSearchQuery(navigate, location, searchQuery);

		const route = createRoute(true, routes.V1ADMIN, routes.PARTICIPANTS, id);
		const options = { suppressNotification: true };

		try {
			const response = await crud.READ<Participant>(navigate, route, options);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getTestParticipants = createAsyncThunk(
	"participants/getTestParticipants",
	async (
		params: getCustomResourceParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, id, queryParams } = params;
		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const {
			name,
			countFrom,
			countTo,
			computeUnit,
			hasGroup,
			recordAudio,
			browser,
			network,
			location: filterLocation,
			videoFeed,
			audioFeed
		} = filters || {};

		let searchQuery = createQuery(
			setQueryParam(NAME, name),
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(HAS_GROUP, hasGroup),
			setQueryParam(RECORD_AUDIO, recordAudio),
			setQueryParam(LIMIT, limit),
			setQueryParam(PAGE, page),
			...multiValueFilterQuery(BROWSER, browser),
			...multiValueFilterQuery(CU, computeUnit),
			...multiValueFilterQuery(NETWORK, network),
			...multiValueFilterQuery(LOCATION, filterLocation),
			...multiValueFilterQuery(VIDEO_FEED, videoFeed),
			...multiValueFilterQuery(AUDIO_FEED, audioFeed)
		);

		searchQuery = replaceQueryQuestionmarkWithAmpersand(searchQuery);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(
			true,
			routes.V1ADMIN,
			routes.TESTS,
			id,
			routes.PARTICIPANTS
		);

		route += createQuery(
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(HAS_GROUP, hasGroup),
			setQueryParam(RECORD_AUDIO, recordAudio),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(OFFSET, getOffset(limit, page || 1)),
			...multiValueFilterQuery(BROWSER, browser),
			...multiValueFilterQuery(CU, computeUnit),
			...multiValueFilterQuery(NETWORK, network),
			...multiValueFilterQuery(LOCATION, filterLocation),
			...multiValueFilterQuery(VIDEO_FEED, videoFeed),
			...multiValueFilterQuery(AUDIO_FEED, audioFeed)
		);

		route = replaceQueryQuestionmarkWithAmpersand(route);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllParticipants
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getGroupParticipants = createAsyncThunk(
	"participants/getGroupParticipants",
	async (
		params: getCustomResourceParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, id, queryParams } = params;
		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const {
			name,
			countFrom,
			countTo,
			computeUnit,
			hasGroup,
			recordAudio,
			browser,
			network,
			location: filterLocation,
			videoFeed,
			audioFeed
		} = filters || {};

		let searchQuery = createQuery(
			setQueryParam(NAME, name),
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(HAS_GROUP, hasGroup),
			setQueryParam(RECORD_AUDIO, recordAudio),
			setQueryParam(LIMIT, limit),
			setQueryParam(PAGE, page),
			...multiValueFilterQuery(BROWSER, browser),
			...multiValueFilterQuery(CU, computeUnit),
			...multiValueFilterQuery(NETWORK, network),
			...multiValueFilterQuery(LOCATION, filterLocation),
			...multiValueFilterQuery(VIDEO_FEED, videoFeed),
			...multiValueFilterQuery(AUDIO_FEED, audioFeed)
		);

		searchQuery = replaceQueryQuestionmarkWithAmpersand(searchQuery);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(
			true,
			routes.V1ADMIN,
			routes.GROUPS,
			id,
			routes.PARTICIPANTS
		);

		route += createQuery(
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(HAS_GROUP, hasGroup),
			setQueryParam(RECORD_AUDIO, recordAudio),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(OFFSET, getOffset(limit, page || 1)),
			...multiValueFilterQuery(BROWSER, browser),
			...multiValueFilterQuery(CU, computeUnit),
			...multiValueFilterQuery(NETWORK, network),
			...multiValueFilterQuery(LOCATION, filterLocation),
			...multiValueFilterQuery(VIDEO_FEED, videoFeed),
			...multiValueFilterQuery(AUDIO_FEED, audioFeed)
		);

		route = replaceQueryQuestionmarkWithAmpersand(route);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllParticipants
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const participantsSlice = createSlice({
	name: "participants",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAllParticipants.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getAllParticipants.fulfilled, (state, action) => {
				state.participantData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getAllParticipants.rejected, (state, action) => {
				state.participantData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getParticipant.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getParticipant.fulfilled, (state, action) => {
				state.participantData = { results: [action.payload as Participant] };
				state.isFetching = false;
			})
			.addCase(getParticipant.rejected, (state, action) => {
				state.participantData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getTestParticipants.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getTestParticipants.fulfilled, (state, action) => {
				state.participantData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getTestParticipants.rejected, (state, action) => {
				state.participantData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getGroupParticipants.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getGroupParticipants.fulfilled, (state, action) => {
				state.participantData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getGroupParticipants.rejected, (state, action) => {
				state.participantData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			});
	}
});

export default participantsSlice.reducer;
