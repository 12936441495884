const columnNames = {
	ACCOUNT_ROLE: "account_role",
	ACTIVATION_DATE: "activation_date",
	DELETED: "deleted",
	GROUP_COUNT: "group_count",
	GROUP_ID: "group_id",
	LABELS: "labels",
	LAST_RUN_DATE: "last_run_date",
	METADATA: "metadata",
	MOS_TEST: "mos_test",
	NEW_TEST_BUILDER: "new_test_builder",
	PARTICIPANT_COUNT: "participant_count",
	PARTICIPANTS: "participants",
	PAYMENT_PLAN: "payment_plan",
	PAYMENT_STATUS: "payment_status",
	PLAN_LIMITS: "plan_limits",
	PROJECT_ID: "project_id",
	PROJECTS: "projects",
	RUNS: "runs",
	SCRIPT: "script",
	SUBSCRIPTION: "subscription",
	SUBSCRIPTION_ID: "subscription_id",
	SUPER_USER: "superuser",
	TEST_BUILDER_TOUR_COMPLETED: "test_builder_tour_completed",
	TESTS: "tests",
	TEST_ID: "test_id",
	TOTAL_CU_COUNT: "total_cu_count",
	TRIAL_EXPIRED: "trial_expired"
};

const limitOptions = [
	{ name: 20, value: 20 },
	{ name: 50, value: 50 },
	{ name: 100, value: 100 },
	{ name: 250, value: 250 }
];

const tableName = {
	ACCOUNTS: "accounts",
	GROUPS: "groups",
	PARTICIPANTS: "participants",
	PROJECTS: "projects",
	PROJECTS_ALL: "projects_all",
	RUNS: "runs",
	SUBSCRIPTIONS: "subscriptions",
	TESTS: "tests"
};

export { columnNames, limitOptions, tableName };
