import { AxiosResponse } from "axios";

import { routes } from "../router/routes";

import { APIError, AuthResponse, RequestConfig } from "../types/api";

import { createRoute } from "../utils/request";
import { setSession } from "../utils/session";

import { alertError, auth } from "./Api";

const authorize = (
	queryString: string,
	options?: RequestConfig
): Promise<AxiosResponse<AuthResponse> | APIError> => {
	const route = `${createRoute(false, routes.AUTH_TOKEN)}/?${queryString}`;

	return new Promise((resolve, reject) => {
		auth.POST(route, {}, options).then(
			response => {
				setSession(response.data);
				resolve(response);
			},
			error => {
				reject(error);
				alertError(error);
			}
		);
	});
};

export { authorize };
