import { RefObject, useEffect } from "react";

import { keyCodes } from "../constants/keys";

type Handler = (event: MouseEvent | TouchEvent | KeyboardEvent) => void;

const useOnClickOutside = (
	ref: RefObject<HTMLDivElement>,
	handler: Handler
): void => {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent): void => {
			if (
				!ref.current ||
				ref.current.contains(event.target as HTMLDivElement)
			) {
				return;
			}

			handler(event);
		};

		const handleKeyDown = (event: KeyboardEvent): void => {
			if (event.keyCode === keyCodes.ESC) handler(event);
		};

		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);
		document.addEventListener("keydown", handleKeyDown);

		return (): void => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [ref, handler]);
};

export { useOnClickOutside };
