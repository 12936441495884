import { NavigateFunction } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import crud from "../../api/crud";

import { routes } from "../../router/routes";

import { APIError, RejectWithValue, StaticData } from "../../types/api";
import { ColumnFilters } from "../../types/filters";

import { createRoute } from "../../utils/request";
import {
	dynamicSort,
	sortAudioFeed,
	sortBrowsers,
	sortComputeUnits,
	sortNetworks,
	sortTestDuration,
	sortVideoFeed
} from "../../utils/method";

type Payload = {
	filters: ColumnFilters;
	name: string;
};

interface IUIReducer {
	columnConfig: { [key: string]: ColumnFilters };
	classificators: StaticData;
	isFetching: boolean;
	errorMessage: APIError | string | null;
}

const initialState: IUIReducer = {
	columnConfig: {},
	classificators: {
		artifact: [],
		assert_status: [],
		audio_feed: [],
		aws_status: [],
		browser: [],
		compute_unit: [],
		file_type: [],
		increment_strategy: [],
		language: [],
		location: [],
		media_type: [],
		member_role: [],
		metric_path: [],
		network: [],
		node_status: [],
		operator: [],
		payment_plan: [],
		payment_status: [],
		property: [],
		result_status: [],
		run_status: [],
		test_duration: [],
		test_mode: [],
		video_feed: []
	},
	isFetching: false,
	errorMessage: null
};

export const fetchAllStatics = createAsyncThunk(
	"ui/fetchAllStatics",
	async (
		navigate: NavigateFunction,
		{ rejectWithValue }
	): Promise<StaticData | RejectWithValue> => {
		const route = createRoute(true, routes.V2, routes.STATICS);

		try {
			const response = await crud.READ<StaticData>(navigate, route);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const uiSlice = createSlice({
	name: "ui",
	initialState,
	reducers: {
		saveColumnConfig: (state, action: { payload: Payload }) => {
			const { filters, name } = action.payload;

			state.columnConfig[name] = filters;
		}
	},

	extraReducers: builder => {
		builder
			.addCase(fetchAllStatics.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(fetchAllStatics.fulfilled, (state, action) => {
				const {
					audio_feed,
					browser,
					compute_unit,
					location,
					network,
					test_duration,
					video_feed
				} = action.payload as StaticData;

				state.isFetching = false;
				state.classificators.audio_feed = sortAudioFeed(audio_feed);
				state.classificators.browser = sortBrowsers(browser);
				state.classificators.compute_unit = sortComputeUnits(compute_unit);
				state.classificators.location = location.sort(dynamicSort("value"));
				state.classificators.network = sortNetworks(network);
				state.classificators.test_duration = sortTestDuration(test_duration);
				state.classificators.video_feed = sortVideoFeed(video_feed);
			})
			.addCase(fetchAllStatics.rejected, (state, action) => {
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			});
	}
});

export const { saveColumnConfig } = uiSlice.actions;
export default uiSlice.reducer;
