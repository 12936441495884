import { FC } from "react";
import { useSelector } from "react-redux";

import AutoFillTable from "../../components/autoFillTable/AutoFillTable";

import { columnNames, tableName } from "../../constants";

import { useAutoFillTableLogic } from "../../hooks/useAutoFillTableLogic";

import {
	getAllGroups,
	getGroup,
	getTestGroups
} from "../../redux/slices/groups";
import { IStoreState } from "../../redux/slices";

import { routes } from "../../router/routes";

import { ConfigFilters } from "../../types/filters";

import { createRoute } from "../../utils/request";

const Groups: FC = (): JSX.Element => {
	const { groupData, isFetching } = useSelector(
		(state: IStoreState) => state.groupReducer
	);

	const configFilters = [
		{
			name: "id",
			elementType: "input"
		},
		{
			name: "name",
			elementType: "input"
		},
		{
			name: "countFrom",
			elementType: "input"
		},
		{
			name: "countTo",
			elementType: "input"
		}
	];

	const { TEST_ID, TOTAL_CU_COUNT, PARTICIPANT_COUNT } = columnNames;

	const route = createRoute(true, routes.TESTS);
	const participantsRoute = createRoute(true, routes.PARTICIPANTS);

	const linkColumns = [
		{ name: TEST_ID, route: route },
		{ name: PARTICIPANT_COUNT, route: participantsRoute }
	];
	const qpResolver = [{ paramMatch: "test_id", fn: getTestGroups }];

	const customColumns = [PARTICIPANT_COUNT, TOTAL_CU_COUNT];

	const {
		columnFilters,
		colNames,
		handleFetchData,
		init,
		onPageChange,
		pager,
		setColumnFilters,
		setDataFilters
	} = useAutoFillTableLogic(
		customColumns,
		[],
		getAllGroups,
		getGroup,
		{} as { key: string; columns: string[] },
		[],
		tableName.GROUPS,
		qpResolver,
		linkColumns
	);

	return (
		<div className="groups">
			<h1>Groups</h1>

			<AutoFillTable
				columnFilters={columnFilters}
				tableColumnNames={colNames}
				data={groupData}
				filters={configFilters as ConfigFilters[]}
				handleFetchData={handleFetchData}
				init={init}
				loading={isFetching}
				nestedData={{} as { key: string; columns: string[] }}
				onPageChange={onPageChange}
				pager={pager}
				setDataFilters={setDataFilters}
				setColumnFilters={setColumnFilters}
				tableName={tableName.GROUPS}
				linkColumns={linkColumns}
			/>
		</div>
	);
};

export default Groups;
