import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import crud from "../../api/crud";

import { queryKeys } from "../../constants";

import { routes } from "../../router/routes";

import {
	APIError,
	RejectWithValue,
	ResponseData,
	ResponseDataWithPaging,
	getAllParams,
	getByIDParams,
	getCustomResourceParams
} from "../../types/api";
import { Run } from "../../types/run";

import {
	dateToUnixTime,
	multiValueFilterQuery,
	replaceQueryQuestionmarkWithAmpersand
} from "../../utils/method";
import {
	appendSearchQuery,
	createQuery,
	createRoute,
	getOffset,
	paginationFallback,
	setQueryParam
} from "../../utils/request";

interface IRunReducer {
	runData: ResponseDataWithPaging | ResponseData | null;
	isFetching: boolean;
	errorMessage: APIError | string | null;
}

const initialState: IRunReducer = {
	runData: null,
	isFetching: false,
	errorMessage: null
};

const {
	FILTER: {
		RUNS: {
			EXECUTION_FINISHED_FROM,
			EXECUTION_FINISHED_TO,
			EXECUTION_STARTED_FROM,
			EXECUTION_STARTED_TO,
			FINISHED,
			ID,
			INCREMENT_STRATEGY,
			LAUNCHING_ACCOUNT_ID,
			METRIC_STATUS,
			MOS_STATUS,
			MOS_TEST,
			NAME,
			PARTICIPANT_TIMEOUT_FROM,
			PARTICIPANT_TIMEOUT_TO,
			PROCESSING_FINISHED_FROM,
			PROCESSING_FINISHED_TO,
			PROCESSING_STARTED_FROM,
			PROCESSING_STARTED_TO,
			STARTED,
			START_INTERVAL_FROM,
			START_INTERVAL_TO,
			STATUS,
			TEST_MODE
		},
		TEST: { ID: TEST_ID }
	},
	LIMIT,
	OFFSET,
	PAGE
} = queryKeys;

export const getAllRuns = createAsyncThunk(
	"runs/getAllRuns",
	async (
		params: getAllParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, queryParams } = params;

		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const {
			executionFinishedFrom,
			executionFinishedTo,
			executionStartedFrom,
			executionStartedTo,
			finished,
			id,
			incrementStrategy,
			launchingAccountId,
			metricStatus,
			mosStatus,
			mosTest,
			testName,
			participantTimeoutFrom,
			participantTimeoutTo,
			processingFinishedFrom,
			processingFinishedTo,
			processingStartedFrom,
			processingStartedTo,
			started,
			startIntervalFrom,
			startIntervalTo,
			status,
			testId,
			testMode
		} = filters || {};

		let searchQuery = createQuery(
			setQueryParam(EXECUTION_FINISHED_FROM, executionFinishedFrom),
			setQueryParam(EXECUTION_FINISHED_TO, executionFinishedTo),
			setQueryParam(EXECUTION_STARTED_FROM, executionStartedFrom),
			setQueryParam(EXECUTION_STARTED_TO, executionStartedTo),
			setQueryParam(FINISHED, finished),
			setQueryParam(ID, id),
			setQueryParam(LAUNCHING_ACCOUNT_ID, launchingAccountId),
			setQueryParam(MOS_TEST, mosTest),
			setQueryParam(NAME, testName),
			setQueryParam(PARTICIPANT_TIMEOUT_FROM, participantTimeoutFrom),
			setQueryParam(PARTICIPANT_TIMEOUT_TO, participantTimeoutTo),
			setQueryParam(PROCESSING_FINISHED_FROM, processingFinishedFrom),
			setQueryParam(PROCESSING_FINISHED_TO, processingFinishedTo),
			setQueryParam(PROCESSING_STARTED_FROM, processingStartedFrom),
			setQueryParam(PROCESSING_STARTED_TO, processingStartedTo),
			setQueryParam(STARTED, started),
			setQueryParam(START_INTERVAL_FROM, startIntervalFrom),
			setQueryParam(START_INTERVAL_TO, startIntervalTo),
			setQueryParam(TEST_ID, testId),
			setQueryParam(LIMIT, limit),
			setQueryParam(PAGE, page),
			...multiValueFilterQuery(INCREMENT_STRATEGY, incrementStrategy),
			...multiValueFilterQuery(METRIC_STATUS, metricStatus),
			...multiValueFilterQuery(MOS_STATUS, mosStatus),
			...multiValueFilterQuery(STATUS, status),
			...multiValueFilterQuery(TEST_MODE, testMode)
		);

		searchQuery = replaceQueryQuestionmarkWithAmpersand(searchQuery);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(true, routes.V1ADMIN, routes.RUNS);

		route += createQuery(
			setQueryParam(
				EXECUTION_FINISHED_FROM,
				dateToUnixTime(executionFinishedFrom as string)
			),
			setQueryParam(
				EXECUTION_FINISHED_TO,
				dateToUnixTime(executionFinishedTo as string, true)
			),
			setQueryParam(
				EXECUTION_STARTED_FROM,
				dateToUnixTime(executionStartedFrom as string)
			),
			setQueryParam(
				EXECUTION_STARTED_TO,
				dateToUnixTime(executionStartedTo as string, true)
			),
			setQueryParam(FINISHED, finished),
			setQueryParam(LAUNCHING_ACCOUNT_ID, launchingAccountId),
			setQueryParam(MOS_TEST, mosTest),
			setQueryParam(NAME, testName),
			setQueryParam(PARTICIPANT_TIMEOUT_FROM, participantTimeoutFrom),
			setQueryParam(PARTICIPANT_TIMEOUT_TO, participantTimeoutTo),
			setQueryParam(
				PROCESSING_FINISHED_FROM,
				dateToUnixTime(processingFinishedFrom as string)
			),
			setQueryParam(
				PROCESSING_FINISHED_TO,
				dateToUnixTime(processingFinishedTo as string, true)
			),
			setQueryParam(
				PROCESSING_STARTED_FROM,
				dateToUnixTime(processingStartedFrom as string)
			),
			setQueryParam(
				PROCESSING_STARTED_TO,
				dateToUnixTime(processingStartedTo as string, true)
			),
			setQueryParam(STARTED, started),
			setQueryParam(START_INTERVAL_FROM, startIntervalFrom),
			setQueryParam(START_INTERVAL_TO, startIntervalTo),
			setQueryParam(TEST_ID, testId),
			setQueryParam(LIMIT, limit),
			setQueryParam(OFFSET, getOffset(limit, page || 1)),
			...multiValueFilterQuery(INCREMENT_STRATEGY, incrementStrategy),
			...multiValueFilterQuery(METRIC_STATUS, metricStatus),
			...multiValueFilterQuery(MOS_STATUS, mosStatus),
			...multiValueFilterQuery(STATUS, status),
			...multiValueFilterQuery(TEST_MODE, testMode)
		);

		route = replaceQueryQuestionmarkWithAmpersand(route);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllRuns
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getRun = createAsyncThunk(
	"runs/getRun",
	async (
		params: getByIDParams,
		{ rejectWithValue }
	): Promise<Run | RejectWithValue> => {
		const { navigate, location, id } = params;

		const searchQuery = createQuery(
			setQueryParam(ID, Number(id)),
			setQueryParam(queryKeys.PAGE, 1),
			setQueryParam(queryKeys.LIMIT, 20)
		);

		appendSearchQuery(navigate, location, searchQuery);

		const route = createRoute(true, routes.V1ADMIN, routes.RUNS, id);
		const options = { suppressNotification: true };

		try {
			const response = await crud.READ<Run>(navigate, route, options);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getTestRuns = createAsyncThunk(
	"runs/getTestRuns",
	async (
		params: getCustomResourceParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, id, queryParams } = params;
		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const {
			executionFinishedFrom,
			executionFinishedTo,
			executionStartedFrom,
			executionStartedTo,
			finished,
			incrementStrategy,
			launchingAccountId,
			metricStatus,
			mosStatus,
			mosTest,
			testName,
			participantTimeoutFrom,
			participantTimeoutTo,
			processingFinishedFrom,
			processingFinishedTo,
			processingStartedFrom,
			processingStartedTo,
			started,
			startIntervalFrom,
			startIntervalTo,
			status,
			testId,
			testMode
		} = filters || {};

		let searchQuery = createQuery(
			setQueryParam(EXECUTION_FINISHED_FROM, executionFinishedFrom),
			setQueryParam(EXECUTION_FINISHED_TO, executionFinishedTo),
			setQueryParam(EXECUTION_STARTED_FROM, executionStartedFrom),
			setQueryParam(EXECUTION_STARTED_TO, executionStartedTo),
			setQueryParam(FINISHED, finished),
			setQueryParam(LAUNCHING_ACCOUNT_ID, launchingAccountId),
			setQueryParam(MOS_TEST, mosTest),
			setQueryParam(NAME, testName),
			setQueryParam(PARTICIPANT_TIMEOUT_FROM, participantTimeoutFrom),
			setQueryParam(PARTICIPANT_TIMEOUT_TO, participantTimeoutTo),
			setQueryParam(PROCESSING_FINISHED_FROM, processingFinishedFrom),
			setQueryParam(PROCESSING_FINISHED_TO, processingFinishedTo),
			setQueryParam(PROCESSING_STARTED_FROM, processingStartedFrom),
			setQueryParam(PROCESSING_STARTED_TO, processingStartedTo),
			setQueryParam(STARTED, started),
			setQueryParam(START_INTERVAL_FROM, startIntervalFrom),
			setQueryParam(START_INTERVAL_TO, startIntervalTo),
			setQueryParam(TEST_ID, testId),
			setQueryParam(LIMIT, limit),
			setQueryParam(PAGE, page),
			...multiValueFilterQuery(INCREMENT_STRATEGY, incrementStrategy),
			...multiValueFilterQuery(METRIC_STATUS, metricStatus),
			...multiValueFilterQuery(MOS_STATUS, mosStatus),
			...multiValueFilterQuery(STATUS, status),
			...multiValueFilterQuery(TEST_MODE, testMode)
		);

		searchQuery = replaceQueryQuestionmarkWithAmpersand(searchQuery);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(
			true,
			routes.V1ADMIN,
			routes.TESTS,
			id,
			routes.RUNS
		);

		route += createQuery(
			setQueryParam(
				EXECUTION_FINISHED_FROM,
				dateToUnixTime(executionFinishedFrom as string)
			),
			setQueryParam(
				EXECUTION_FINISHED_TO,
				dateToUnixTime(executionFinishedTo as string, true)
			),
			setQueryParam(
				EXECUTION_STARTED_FROM,
				dateToUnixTime(executionStartedFrom as string)
			),
			setQueryParam(
				EXECUTION_STARTED_TO,
				dateToUnixTime(executionStartedTo as string, true)
			),
			setQueryParam(FINISHED, finished),
			setQueryParam(LAUNCHING_ACCOUNT_ID, launchingAccountId),
			setQueryParam(MOS_TEST, mosTest),
			setQueryParam(NAME, testName),
			setQueryParam(PARTICIPANT_TIMEOUT_FROM, participantTimeoutFrom),
			setQueryParam(PARTICIPANT_TIMEOUT_TO, participantTimeoutTo),
			setQueryParam(
				PROCESSING_FINISHED_FROM,
				dateToUnixTime(processingFinishedFrom as string)
			),
			setQueryParam(
				PROCESSING_FINISHED_TO,
				dateToUnixTime(processingFinishedTo as string, true)
			),
			setQueryParam(
				PROCESSING_STARTED_FROM,
				dateToUnixTime(processingStartedFrom as string)
			),
			setQueryParam(
				PROCESSING_STARTED_TO,
				dateToUnixTime(processingStartedTo as string, true)
			),
			setQueryParam(STARTED, started),
			setQueryParam(START_INTERVAL_FROM, startIntervalFrom),
			setQueryParam(START_INTERVAL_TO, startIntervalTo),
			setQueryParam(TEST_ID, testId),
			setQueryParam(LIMIT, limit),
			setQueryParam(OFFSET, getOffset(limit, page || 1)),
			...multiValueFilterQuery(INCREMENT_STRATEGY, incrementStrategy),
			...multiValueFilterQuery(METRIC_STATUS, metricStatus),
			...multiValueFilterQuery(MOS_STATUS, mosStatus),
			...multiValueFilterQuery(STATUS, status),
			...multiValueFilterQuery(TEST_MODE, testMode)
		);

		route = replaceQueryQuestionmarkWithAmpersand(route);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllRuns
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const runsSlice = createSlice({
	name: "runs",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAllRuns.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getAllRuns.fulfilled, (state, action) => {
				state.runData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getAllRuns.rejected, (state, action) => {
				state.runData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getRun.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getRun.fulfilled, (state, action) => {
				state.runData = { results: [action.payload as Run] };
				state.isFetching = false;
			})
			.addCase(getRun.rejected, (state, action) => {
				state.runData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getTestRuns.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getTestRuns.fulfilled, (state, action) => {
				state.runData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getTestRuns.rejected, (state, action) => {
				state.runData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			});
	}
});

export default runsSlice.reducer;
