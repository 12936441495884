import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import crud from "../../api/crud";

import { queryKeys } from "../../constants";

import { routes } from "../../router/routes";

import {
	APIError,
	RejectWithValue,
	ResponseData,
	ResponseDataWithPaging,
	getAllParams,
	getByIDParams
} from "../../types/api";
import { Subscription } from "../../types/subscriptions";

import { dateToUnixTime } from "../../utils/method";
import {
	appendSearchQuery,
	createQuery,
	createRoute,
	getOffset,
	paginationFallback,
	setQueryParam
} from "../../utils/request";
import {
	multiValueFilterQuery,
	replaceQueryQuestionmarkWithAmpersand
} from "../../utils/method";

interface ISubscriptionReducer {
	subscriptionData: ResponseDataWithPaging | ResponseData | null;
	isFetching: boolean;
	errorMessage: APIError | string | null;
}

const initialState: ISubscriptionReducer = {
	subscriptionData: null,
	isFetching: false,
	errorMessage: null
};

const {
	FILTER: {
		SUBSCRIPTIONS: {
			PAYMENT_PLAN,
			PAYMENT_STATUS,
			ACTIVATION_FROM,
			ACTIVATION_TO,
			BILLING_EMAIL,
			MAX_MONTHLY_CU_FROM,
			MAX_MONTHLY_CU_TO,
			MAX_TEST_CU_FROM,
			MAX_TEST_CU_TO,
			MAX_PARTICIPANT_CU,
			MAX_DURATION,
			MOS_ENABLED
		}
	},
	ID,
	LIMIT,
	OFFSET,
	PAGE
} = queryKeys;

export const getAllSubscriptions = createAsyncThunk(
	"subscriptiond/getAllSubscriptions",
	async (
		params: getAllParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, queryParams } = params;

		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};

		const {
			paymentPlan,
			paymentStatus,
			activationFrom,
			activationTo,
			billingEmail,
			maxMonthlyCuFrom,
			maxMonthlyCuTo,
			maxTestCuFrom,
			maxTestCuTo,
			maxParticipantCu,
			maxDuration,
			mosEnabled
		} = filters || {};

		let searchQuery = createQuery(
			setQueryParam(ACTIVATION_FROM, activationFrom),
			setQueryParam(ACTIVATION_TO, activationTo),
			setQueryParam(BILLING_EMAIL, billingEmail),
			setQueryParam(MAX_MONTHLY_CU_FROM, maxMonthlyCuFrom),
			setQueryParam(MAX_MONTHLY_CU_TO, maxMonthlyCuTo),
			setQueryParam(MAX_TEST_CU_FROM, maxTestCuFrom),
			setQueryParam(MAX_TEST_CU_TO, maxTestCuTo),
			setQueryParam(MOS_ENABLED, mosEnabled),
			setQueryParam(LIMIT, limit),
			setQueryParam(PAGE, page),
			...multiValueFilterQuery(PAYMENT_PLAN, paymentPlan),
			...multiValueFilterQuery(PAYMENT_STATUS, paymentStatus),
			...multiValueFilterQuery(MAX_PARTICIPANT_CU, maxParticipantCu),
			...multiValueFilterQuery(MAX_DURATION, maxDuration)
		);

		searchQuery = replaceQueryQuestionmarkWithAmpersand(searchQuery);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(true, routes.V1ADMIN, routes.SUBSCRIPTIONS);

		route += createQuery(
			setQueryParam(ACTIVATION_FROM, dateToUnixTime(activationFrom as string)),
			setQueryParam(ACTIVATION_TO, dateToUnixTime(activationTo as string)),
			setQueryParam(BILLING_EMAIL, billingEmail),
			setQueryParam(MAX_MONTHLY_CU_FROM, maxMonthlyCuFrom),
			setQueryParam(MAX_MONTHLY_CU_TO, maxMonthlyCuTo),
			setQueryParam(MAX_TEST_CU_FROM, maxTestCuFrom),
			setQueryParam(MAX_TEST_CU_TO, maxTestCuTo),
			setQueryParam(MOS_ENABLED, mosEnabled),
			setQueryParam(LIMIT, limit),
			setQueryParam(OFFSET, getOffset(limit, page || 1)),
			...multiValueFilterQuery(PAYMENT_PLAN, paymentPlan),
			...multiValueFilterQuery(PAYMENT_STATUS, paymentStatus),
			...multiValueFilterQuery(MAX_PARTICIPANT_CU, maxParticipantCu),
			...multiValueFilterQuery(MAX_DURATION, maxDuration)
		);

		route = replaceQueryQuestionmarkWithAmpersand(route);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllSubscriptions
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getSubscription = createAsyncThunk(
	"subscriptions/getSubscription",
	async (
		params: getByIDParams,
		{ rejectWithValue }
	): Promise<Subscription | RejectWithValue> => {
		const { navigate, location, id } = params;

		const searchQuery = createQuery(
			setQueryParam(ID, Number(id)),
			setQueryParam(queryKeys.PAGE, 1),
			setQueryParam(queryKeys.LIMIT, 20)
		);

		appendSearchQuery(navigate, location, searchQuery);

		const route = createRoute(true, routes.V1ADMIN, routes.SUBSCRIPTIONS, id);
		const options = { suppressNotification: true };

		try {
			const response = await crud.READ<Subscription>(navigate, route, options);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const subscriptionsSlice = createSlice({
	name: "subscriptions",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAllSubscriptions.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getAllSubscriptions.fulfilled, (state, action) => {
				state.subscriptionData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getAllSubscriptions.rejected, (state, action) => {
				state.subscriptionData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getSubscription.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getSubscription.fulfilled, (state, action) => {
				state.subscriptionData = { results: [action.payload as Subscription] };
				state.isFetching = false;
			})
			.addCase(getSubscription.rejected, (state, action) => {
				state.subscriptionData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			});
	}
});

export default subscriptionsSlice.reducer;
