import { createSlice } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";

type Payload = {
	navigate: NavigateFunction;
	authState: boolean;
	nextRoute?: string;
};

interface IAuthReducer {
	loggedIn: boolean;
}

const initialState: IAuthReducer = {
	loggedIn: false
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		checkAuthStatus: (state, action: { payload: Payload }) => {
			const { authState, navigate, nextRoute } = action.payload;

			if (state.loggedIn === authState) return;

			state.loggedIn = authState;

			if (!authState || nextRoute) {
				navigate(nextRoute || routes.LOG_IN);
			}
		},
		logoutUser: (state, action: { payload: NavigateFunction }) => {
			state.loggedIn = false;
			checkAuthStatus({ navigate: action.payload, authState: false });
		}
	}
});

export const { checkAuthStatus, logoutUser } = authSlice.actions;

export default authSlice.reducer;
