const queryKeys = {
	AUTHORIZATION_CODE: "authorization_code",
	CODE: "code",
	EMAIL: "email",
	FORGOT_PASSWORD: "forgot_password",
	FILTER: {
		ACCOUNT: {
			EMAIL: "filter_email",
			FIRST_NAME: "filter_first_name",
			LAST_LOGIN_FROM: "filter_last_login_from",
			LAST_LOGIN_TO: "filter_last_login_to",
			LAST_NAME: "filter_last_name",
			SUPER_USER: "filter_super_user"
		},
		GROUPS: {
			COUNT_FROM: "filter_count_from",
			COUNT_TO: "filter_count_to",
			NAME: "filter_name"
		},
		PARTICIPANTS: {
			NAME: "filter_name",
			COUNT_FROM: "filter_count_from",
			COUNT_TO: "filter_count_to",
			CU: "filter_compute_unit",
			HAS_GROUP: "filter_has_group",
			RECORD_AUDIO: "filter_record_audio",
			BROWSER: "filter_browser",
			NETWORK: "filter_network",
			LOCATION: "filter_location",
			VIDEO_FEED: "filter_video_feed",
			AUDIO_FEED: "filter_audio_feed"
		},
		PROJECT: {
			ACCOUNT_ID: "account_id",
			HAS_CUSTOM_AWS: "filter_has_aws_info",
			HAS_SUBSCRIPTION: "filter_has_subscription",
			LANGUAGE: "filter_language",
			NAME: "filter_name",
			SUBSCRIPTION_ID: "subscription_id"
		},

		RUNS: {
			EXECUTION_FINISHED_FROM: "filter_execution_finished_from",
			EXECUTION_FINISHED_TO: "filter_execution_finished_to",
			EXECUTION_STARTED_FROM: "filter_execution_started_from",
			EXECUTION_STARTED_TO: "filter_execution_started_to",
			FINISHED: "filter_finished",
			ID: "id",
			INCREMENT_STRATEGY: "filter_increment_strategy",
			LAUNCHING_ACCOUNT_ID: "filter_launching_account_id",
			METRIC_STATUS: "filter_metric_status",
			MOS_STATUS: "filter_mos_status",
			MOS_TEST: "filter_mos_test",
			NAME: "filter_test_name",
			PARTICIPANT_TIMEOUT_FROM: "filter_participant_timeout_from",
			PARTICIPANT_TIMEOUT_TO: "filter_participant_timeout_to",
			PROCESSING_FINISHED_FROM: "filter_processing_finished_from",
			PROCESSING_FINISHED_TO: "filter_processing_finished_to",
			PROCESSING_STARTED_FROM: "filter_processing_started_from",
			PROCESSING_STARTED_TO: "filter_processing_started_to",
			STARTED: "filter_started",
			START_INTERVAL_FROM: "filter_start_interval_from",
			START_INTERVAL_TO: "filter_start_interval_to",
			STATUS: "filter_status",
			TEST_MODE: "filter_test_mode"
		},
		SUBSCRIPTIONS: {
			ACTIVATION_FROM: "filter_activation_from",
			ACTIVATION_TO: "filter_activation_to",
			BILLING_EMAIL: "filter_billing_email",
			MAX_DURATION: "filter_max_duration",
			MAX_MONTHLY_CU_FROM: "filter_max_monthly_cu_from",
			MAX_MONTHLY_CU_TO: "filter_max_monthly_cu_to",
			MAX_PARTICIPANT_CU: "filter_max_participant_cu",
			MAX_TEST_CU_FROM: "filter_max_test_cu_from",
			MAX_TEST_CU_TO: "filter_max_test_cu_to",
			MOS_ENABLED: "filter_mos_enabled",
			PAYMENT_PLAN: "filter_payment_plan",
			PAYMENT_STATUS: "filter_payment_status"
		},
		TEST: {
			NAME: "filter_name",
			PROJECT_ID: "project_id",
			ID: "test_id",
			TEST_MODE: "filter_test_mode",
			INCREMENT_STRATEGY: "filter_increment_strategy",
			START_INTERVAL_FROM: "filter_start_interval_from",
			START_INTERVAL_TO: "filter_start_interval_to",
			PARTICIPANT_TIMEOUT_FROM: "filter_participant_timeout_from",
			PARTICIPANT_TIMEOUT_TO: "filter_participant_timeout_to"
		}
	},
	ID: "id",
	LIMIT: "limit",
	OFFSET: "offset",
	PAGE: "page",
	PRESERVED: "preserved"
};

const sortFields = {
	CREATED: "created",
	UPDATED: "updated",
	LAST_RUN: "last_run"
};

export { queryKeys, sortFields };
