import { FC, useRef } from "react";
import { useDispatch } from "react-redux";

import { Checkbox } from "ui-kit";

import { AppDispatch } from "../../redux/store";
import { saveColumnConfig } from "../../redux/slices/ui";

import { useOnClickOutside } from "../../hooks/useOnClickOutside";

import { formatObjectKey } from "../../utils/method";

import { ColumnFilters } from "../../types/filters";

interface IProps {
	dropDownActive: boolean;
	filters: ColumnFilters;
	setFilters(filter: ColumnFilters): void;
	toggleDropdown(): void;
	tableName: string;
}

const ColumnFilter: FC<IProps> = ({
	dropDownActive,
	filters,
	setFilters,
	toggleDropdown,
	tableName
}): JSX.Element => {
	const dropdownElem = useRef(null);
	const dispatch = useDispatch<AppDispatch>();

	useOnClickOutside(dropdownElem, () => {
		if (!dropDownActive) return;

		toggleDropdown();
	});

	const handleChange = (
		fieldName: string,
		value: string | boolean | number
	): void => {
		if (typeof value !== "boolean") return;

		const updatedFilters: ColumnFilters = {
			...filters,
			[fieldName]: value
		};

		setFilters(updatedFilters);
		dispatch(saveColumnConfig({ name: tableName, filters: updatedFilters }));
	};

	const formatLabel = (name: string): string =>
		formatObjectKey(name.replace("filter", ""));

	return (
		<div className="column-filter" ref={dropdownElem} onClick={toggleDropdown}>
			<span>Configure</span>

			<ul
				className={`column-filter__list${
					dropDownActive ? " column-filter__list--active" : ""
				}`}
			>
				{Object.keys(filters).map(column => (
					<li className="column-filter__element" key={column}>
						<Checkbox
							onChange={handleChange}
							checked={filters[column]}
							label={formatLabel(column)}
							name={column}
						/>
					</li>
				))}
			</ul>
		</div>
	);
};

export default ColumnFilter;
