import { FC } from "react";
import { useSelector } from "react-redux";

import AutoFillTable from "../../components/autoFillTable/AutoFillTable";

import { columnNames, tableName } from "../../constants";

import { useAutoFillTableLogic } from "../../hooks/useAutoFillTableLogic";

import {
	getAllTests,
	getProjectTests,
	getTest
} from "../../redux/slices/tests";
import { IStoreState } from "../../redux/slices";

import { routes } from "../../router/routes";

import { ConfigFilters } from "../../types/filters";

import { createRoute } from "../../utils/request";

const Tests: FC = (): JSX.Element => {
	const { testData, isFetching } = useSelector(
		(state: IStoreState) => state.testReducer
	);

	const configFilters = [
		{
			name: "id",
			elementType: "input"
		},
		{
			name: "name",
			elementType: "input"
		},
		{
			name: "projectId",
			elementType: "input"
		},
		{
			name: "startIntervalFrom",
			elementType: "input"
		},
		{
			name: "startIntervalTo",
			elementType: "input"
		},
		{
			name: "participantTimeoutFrom",
			elementType: "input"
		},
		{
			name: "participantTimeoutTo",
			elementType: "input"
		},

		{
			name: "testMode",
			elementType: "multiselect",
			label: "Test Mode",
			options: [
				{ label: "Load", key: "load" },
				{ label: "Performance", key: "performance" },
				{ label: "Session Recording", key: "session-record" }
			],
			selected: ""
		},
		{
			name: "incrementStrategy",
			elementType: "multiselect",
			label: "Increment Strategy",
			options: [
				{ label: "Linear Participant", key: "linear" },
				{ label: "Linear Group", key: "linear_group" },
				{ label: "Random Participant", key: "random" },
				{ label: "Random Group", key: "random_group" }
			],
			selected: ""
		}
	];

	const {
		GROUP_COUNT,
		LAST_RUN_DATE,
		MOS_TEST,
		PARTICIPANT_COUNT,
		PROJECT_ID,
		RUNS,
		SCRIPT,
		TOTAL_CU_COUNT
	} = columnNames;

	const projectsRoute = createRoute(true, routes.PROJECTS);
	const participantsRoute = createRoute(true, routes.PARTICIPANTS);
	const runsRoute = createRoute(true, routes.RUNS);
	const groupsRoute = createRoute(true, routes.GROUPS);

	const customColumns = [
		GROUP_COUNT,
		PARTICIPANT_COUNT,
		LAST_RUN_DATE,
		TOTAL_CU_COUNT,
		MOS_TEST,
		RUNS
	];
	const suppressColumns = [SCRIPT];

	const linkColumns = [
		{ name: PROJECT_ID, route: projectsRoute },
		{ name: PARTICIPANT_COUNT, route: participantsRoute },
		{ name: RUNS, route: runsRoute },
		{ name: GROUP_COUNT, route: groupsRoute }
	];

	const qpResolver = [{ paramMatch: "project_id", fn: getProjectTests }];

	const {
		columnFilters,
		colNames,
		handleFetchData,
		init,
		onPageChange,
		pager,
		setColumnFilters,
		setDataFilters
	} = useAutoFillTableLogic(
		customColumns,
		[],
		getAllTests,
		getTest,
		{} as { key: string; columns: string[] },
		suppressColumns,
		tableName.TESTS,
		qpResolver,
		linkColumns
	);

	return (
		<div className="tests">
			<h1>Tests</h1>

			<AutoFillTable
				columnFilters={columnFilters}
				tableColumnNames={colNames}
				data={testData}
				filters={configFilters as ConfigFilters[]}
				handleFetchData={handleFetchData}
				init={init}
				loading={isFetching}
				nestedData={{} as { key: string; columns: string[] }}
				onPageChange={onPageChange}
				pager={pager}
				setDataFilters={setDataFilters}
				setColumnFilters={setColumnFilters}
				tableName={tableName.TESTS}
				linkColumns={linkColumns}
			/>
		</div>
	);
};

export default Tests;
