import { NavigateFunction, Location } from "react-router-dom";

import qs from "query-string";

import { queryKeys } from "../constants";

import { store as reduxStore } from "../redux/store";

import {
	APIError,
	QueryParam,
	QueryParams,
	RequestConfig,
	ResponseDataWithPaging
} from "../types/api";
import { PagerData } from "../types/pagination";
import { AppliedFilters } from "../types/filters";

import { snakeToCamel } from "./method";

const createRoute = (strict: boolean, ...paths: string[]): string => {
	let requestPath = "";

	paths.forEach(path => {
		if (!path) return;

		if (
			String(path).slice(0, 1) !== "/" &&
			requestPath.slice(-1) !== "/" &&
			String(path).slice(0, 1) !== "#"
		) {
			requestPath += "/";
		}

		requestPath += path;
	});

	if (strict) {
		requestPath += "/";
	}

	return requestPath;
};

const getRequestConfig = (): RequestConfig => {
	const requestConfig = {
		headers: {
			Authorization: `Bearer ${
				reduxStore.getState().persistedStorage.access_token
			}`
		}
	};

	return requestConfig;
};

const createQuery = (...params: QueryParam[]): string => {
	let query = "";

	params.forEach(param => {
		const { key, value } = param;

		if (typeof value !== "boolean" && !value) return;

		const prefix = query === "" ? "?" : "&";
		query += `${prefix}${key}=${encodeURIComponent(value)}`;
	});

	return query;
};

const setQueryParam = (key: string, paramValue: unknown): QueryParam => {
	let value = paramValue;

	if (key === queryKeys.PAGE) {
		value = Number(paramValue) > 1 ? paramValue : 0;
	}

	return { key, value: value as string | number };
};

const getOffset = (limit: number, page: number): number => limit * (page - 1);

const getQueryParam = (location: Location, param: string): string => {
	const { search } = location;

	if (!search) return "";

	return (qs.parse(search)[param] as string) || "";
};

const paginationFallback = (
	navigate: NavigateFunction,
	location: Location,
	queryParams: QueryParams,
	pagination: PagerData,
	actionCreator: Function
): Promise<ResponseDataWithPaging> => {
	const updatedQueryParams = {
		...queryParams,
		pagination: {
			...pagination,
			page: 1
		}
	};

	const searchQuery = createQuery(
		setQueryParam(queryKeys.PAGE, 1),
		setQueryParam(queryKeys.LIMIT, pagination.limit)
	);

	location.search = searchQuery;
	navigate(location, { replace: true });

	return reduxStore.dispatch(
		actionCreator({
			navigate,
			location,
			queryParams: updatedQueryParams
		})
	);
};

const getResponseError = (error: APIError): string =>
	error?.response?.data?.error || "An unexpected error occurred";

const appendSearchQuery = (
	navigate: NavigateFunction,
	location: Location,
	query: string
): void => {
	if (location.search === query) return;

	const queryParams = qs.parse(query);

	if ("id" in queryParams) {
		const updatedParams = {
			id: queryParams.id
		};

		location.search = qs.stringify(updatedParams);
		navigate(location, { replace: true });

		return;
	}

	location.search = qs.stringify(queryParams);
	navigate(location, { replace: true });
};

const getStateFromQuery = (location: Location): AppliedFilters => {
	const parsed = qs.parse(location.search) as AppliedFilters;

	const params: AppliedFilters = {};

	const { LIMIT, PAGE } = queryKeys;

	Object.keys(parsed).forEach((key: string) => {
		if (key === LIMIT || key === PAGE) return;

		const formattedKey = snakeToCamel(key.replace("filter_", ""));
		params[formattedKey] = parsed[key] || "";
	});

	return params;
};

export {
	appendSearchQuery,
	createQuery,
	createRoute,
	getOffset,
	getQueryParam,
	getRequestConfig,
	getResponseError,
	getStateFromQuery,
	paginationFallback,
	setQueryParam
};
