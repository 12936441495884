import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import crud from "../../api/crud";

import { queryKeys } from "../../constants";

import { routes } from "../../router/routes";

import {
	APIError,
	RejectWithValue,
	ResponseData,
	ResponseDataWithPaging,
	getAllParams,
	getByIDParams,
	getCustomResourceParams
} from "../../types/api";
import { Group } from "../../types/groups";

import {
	appendSearchQuery,
	createQuery,
	createRoute,
	getOffset,
	paginationFallback,
	setQueryParam
} from "../../utils/request";

interface IGroupReducer {
	groupData: ResponseDataWithPaging | ResponseData | null;
	isFetching: boolean;
	errorMessage: APIError | string | null;
}

const initialState: IGroupReducer = {
	groupData: null,
	isFetching: false,
	errorMessage: null
};

const {
	FILTER: {
		GROUPS: { COUNT_FROM, COUNT_TO, NAME }
	},
	ID,
	LIMIT,
	OFFSET,
	PAGE
} = queryKeys;

export const getAllGroups = createAsyncThunk(
	"groups/getAllGroups",
	async (
		params: getAllParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, queryParams } = params;

		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const { countFrom, countTo, name } = filters || {};

		const searchQuery = createQuery(
			setQueryParam(NAME, name),
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(LIMIT, limit),
			setQueryParam(PAGE, page)
		);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(true, routes.V1ADMIN, routes.GROUPS);

		route += createQuery(
			setQueryParam(NAME, name),
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(LIMIT, limit),
			setQueryParam(OFFSET, getOffset(limit, page || 1))
		);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllGroups
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getGroup = createAsyncThunk(
	"groups/getGroup",
	async (
		params: getByIDParams,
		{ rejectWithValue }
	): Promise<Group | RejectWithValue> => {
		const { navigate, location, id } = params;

		const searchQuery = createQuery(
			setQueryParam(ID, Number(id)),
			setQueryParam(queryKeys.PAGE, 1),
			setQueryParam(queryKeys.LIMIT, 20)
		);
		appendSearchQuery(navigate, location, searchQuery);

		const route = createRoute(true, routes.V1ADMIN, routes.GROUPS, id);
		const options = { suppressNotification: true };

		try {
			const response = await crud.READ<Group>(navigate, route, options);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getTestGroups = createAsyncThunk(
	"groups/getTestGroups",
	async (
		params: getCustomResourceParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, id, queryParams } = params;
		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const { countFrom, countTo, name } = filters || {};

		const searchQuery = createQuery(
			setQueryParam(NAME, name),
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(LIMIT, limit),
			setQueryParam(PAGE, page)
		);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(
			true,
			routes.V1ADMIN,
			routes.TESTS,
			id,
			routes.GROUPS
		);

		route += createQuery(
			setQueryParam(NAME, name),
			setQueryParam(COUNT_FROM, countFrom),
			setQueryParam(COUNT_TO, countTo),
			setQueryParam(LIMIT, limit),
			setQueryParam(OFFSET, getOffset(limit, page || 1))
		);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllGroups
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const groupsSlice = createSlice({
	name: "groups",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAllGroups.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getAllGroups.fulfilled, (state, action) => {
				state.groupData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getAllGroups.rejected, (state, action) => {
				state.groupData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getGroup.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getGroup.fulfilled, (state, action) => {
				state.groupData = { results: [action.payload as Group] };
				state.isFetching = false;
			})
			.addCase(getGroup.rejected, (state, action) => {
				state.groupData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getTestGroups.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getTestGroups.fulfilled, (state, action) => {
				state.groupData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getTestGroups.rejected, (state, action) => {
				state.groupData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			});
	}
});

export default groupsSlice.reducer;
