import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import accountReducer from "../slices/accounts";
import groupReducer from "../slices/groups";
import authReducer from "../slices/auth";
import participantReducer from "../slices/participants";
import persistedStorage from "../slices/persistedStorage";
import projectReducer from "../slices/projects";
import runReducer from "../slices/runs";
import subscriptionReducer from "../slices/subscriptions";
import testReducer from "../slices/tests";
import uiReducer from "../slices/ui";

const persistConfig = {
	key: "aw-root",
	storage,
	whitelist: ["persistedStorage", "uiReducer"]
};

const rootReducer = combineReducers({
	authReducer,
	accountReducer,
	groupReducer,
	participantReducer,
	persistedStorage,
	projectReducer,
	runReducer,
	subscriptionReducer,
	testReducer,
	uiReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type IStoreState = ReturnType<typeof rootReducer>;

export default persistedReducer;
