import { Translations } from "ui-kit";

const config = {
	env: process.env.REACT_APP_ENV,
	appURL: process.env.REACT_APP_APP_URL,
	authURL: process.env.REACT_APP_AUTH_URL,
	apiURL: process.env.REACT_APP_API_URL,
	basicToken: process.env.REACT_APP_BASIC_TOKEN,
	clientID: process.env.REACT_APP_ADMIN_CLIENT_ID,
	sentryKey: process.env.REACT_APP_SENTRY_KEY,
	siteUrl: process.env.REACT_APP_LANDING_URL,
	version: "1.0.0"
};

const getUrl = (): string =>
	process.env.REACT_APP_ENV === "review"
		? window.location.origin
		: (process.env.REACT_APP_ADMIN_APP_URL as string);

const uiKitTranslations: Translations = {
	CANCEL: "Cancel",
	CLEAR: "Clear",
	CLEAR_ALL: "Clear all",
	COPIED: "Copied",
	COPY: "Copy",
	DROPDOWN_MULTISELECT: "more selected",
	HIDE: "Hide",
	ITEMS_PER_PAGE: "items per page",
	NO_RESULTS_FOUND: "No results found",
	OF: "of",
	SEARCH: "Search",
	SELECT: "Select",
	SELECT_DATE: "Select date",
	SELECT_OPTION:"Select an option",
	SHOW: "Show",
	TO_PAGE: "to page",
	UPGRADE: "Upgrade"
};

export { config, getUrl, uiKitTranslations };
