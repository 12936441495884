import { FC } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader } from "ui-kit";

import Statistics from "../../components/statistics/Statistics";

import { routes } from "../../router/routes";
import { IStoreState } from "../../redux/slices";

const Dashboard: FC = (): JSX.Element => {
	const { isFetching } = useSelector((state: IStoreState) => state.uiReducer);

	if (isFetching) return <Loader centered />;

	return (
		<div className="dashboard">
			<div className="spacer" />

			<div className="dashboard__info">
				<div className="dashboard__nav">
					<Link className="link link--underline" to={routes.ACCOUNTS}>
						Accounts
					</Link>

					<Link className="link link--underline" to={routes.PROJECTS}>
						Projects
					</Link>

					<Link className="link link--underline" to={routes.TESTS}>
						Tests
					</Link>

					<Link className="link link--underline" to={routes.RUNS}>
						Runs
					</Link>

					<Link className="link link--underline" to={routes.GROUPS}>
						Groups
					</Link>

					<Link className="link link--underline" to={routes.PARTICIPANTS}>
						Participants
					</Link>

					<Link className="link link--underline" to={routes.SUBSCRIPTIONS}>
						Subscriptions
					</Link>
				</div>
			</div>

			<Statistics />
		</div>
	);
};

export default Dashboard;
