import moment from "moment";
import { CheckBoxState } from "ui-kit";

import { sortFields } from "../constants";
import { QueryParam, Static } from "../types/api";
import { AppliedFilters, ConfigFilters } from "../types/filters";

import { setQueryParam } from "./request";

type Obj = {
	[key: string]: unknown;
};

const arraysEqual = (arr1: unknown[], arr2: unknown[]): boolean => {
	const difference = arr1
		.filter(elem => !arr2.includes(elem))
		.concat(arr2.filter(elem => !arr1.includes(elem)));

	return difference.length === 0;
};

const objectValues = (obj: Obj): unknown[] => {
	if (Object.values) {
		return Object.values(obj);
	}

	return Object.keys(obj).map(elem => obj[elem as keyof typeof obj]);
};

const isNumeric = (num: string): boolean =>
	!Number.isNaN(parseFloat(num)) && Number.isFinite(num);

const isDate = (date: string | number): boolean => {
	if (!date) return false;

	return moment(date, moment.ISO_8601).isValid();
};

const dateToUnixTime = (
	date: string | null | undefined,
	endOfDay?: boolean
): number | null => {
	if (!date) return null;

	return endOfDay
		? moment.utc(date).endOf("day").unix()
		: moment.utc(date).unix();
};

const formatDateTime = (
	date: string,
	options?: Intl.DateTimeFormatOptions,
	locale = "en-US"
): string => {
	if (!isDate(date)) {
		return date;
	}

	const dateOptions: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		hour12: false,
		timeZone: "UTC",
		...options
	};

	const returnDate = new Date(date);
	return new Intl.DateTimeFormat(locale, dateOptions).format(returnDate);
};

type ColumnFilterState = {
	[key: string]: boolean;
};

const initializeKeys = (
	keys: string[],
	disabledColumns: string[]
): ColumnFilterState => {
	const newObj: ColumnFilterState = {};

	keys.forEach((key: string) => {
		newObj[key] = disabledColumns.includes(key) ? false : true;
	});

	return newObj;
};

const initializeStateFromFilters = (
	filters: ConfigFilters[]
): AppliedFilters => {
	const initialState: AppliedFilters = {};

	filters.forEach((key: ConfigFilters) => {
		switch (key.elementType) {
			case "radio":
				initialState[key.name] = key.selected || "";
				break;
			default:
				initialState[key.name] = "";
		}
	});

	return initialState;
};

const initializeStateFromQParams = (
	filters: ConfigFilters[],
	queryResults: AppliedFilters
): AppliedFilters => {
	const castToBoolean = (val: string): boolean => val === "true";

	const getMultiselectProps = (
		appliedFilters: AppliedFilters,
		configFilters: ConfigFilters[]
	): string[] => {
		const multiselectProps: string[] = [];

		Object.keys(appliedFilters).forEach(key => {
			const foundElement = configFilters.find(
				(item: ConfigFilters) =>
					item.name === key && item.elementType === "multiselect"
			);

			if (foundElement) {
				multiselectProps.push(key);
			}
		});

		return multiselectProps;
	};

	const state: AppliedFilters | Record<string, boolean> =
		initializeStateFromFilters(filters);

	// Derive state values from url query params
	Object.keys(state).forEach((key: string) => {
		let filter = queryResults[key as keyof AppliedFilters];

		if (typeof filter === "undefined") return;

		if (
			getMultiselectProps(queryResults, filters).includes(key) &&
			!Array.isArray(filter)
		) {
			filter = [filter] as any;
		}

		if (Array.isArray(filter)) {
			state[key as keyof AppliedFilters] = filter.reduce(
				(acc: Record<string, boolean>, curr: string) => {
					acc[curr] = true;
					return acc;
				},
				{}
			);

			return;
		}

		state[key as keyof AppliedFilters] =
			filter === "true" || filter === "false" ? castToBoolean(filter) : filter;
	});

	return state;
};

const formatObjectKey = (key: string): string =>
	key
		// Remove unknown uppercasing within snake case
		.replace(/_([A-Z])/g, str => str.toLowerCase())
		// Remove `is` from boolean fields
		.replace(/^is_/g, "")
		// Replace underscores with a space
		.replace(/_/g, " ")
		// un-camelcase (insert space between lowercase and uppercase letters)
		.replace(/([a-z])([A-Z])/g, "$1 $2")
		// Insert space between unknown letters and numbers
		.replace(/([a-zA-Z])(\d+)/g, "$1 $2")
		.replace(/(\d+)([a-zA-Z])/g, "$1 $2");

const objectEmpty = (obj: Obj): boolean => Object.keys(obj).length === 0;

const formatTableValue = (value: string | number | boolean | Date): string => {
	switch (true) {
		case value === "0001-01-01T00:00:00Z":
			return "-";
		case typeof value === "string" && isDate(value):
			return formatDateTime(value as string);
		case typeof value === "boolean":
			return value ? "Yes" : "No";
		case value && typeof value === "object":
			const arr = [];
			for (const [key, v] of Object.entries(value)) {
				arr.push(typeof v === "object" ? formatTableValue(v) : `${key}: ${v}`);
			}

			return arr.join("; \n");
		default:
			return value?.toString();
	}
};

const snakeToCamel = (str: string): string =>
	str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace("_", ""));

const objectEqual = (a: unknown, b: unknown): boolean => {
	const { isArray } = Array;
	const keyList = Object.keys;
	const hasProp = Object.prototype.hasOwnProperty;

	// Check String, Number and Boolean equality cases
	if (a === b) return true;

	// Typeof object refers to Arrays, Date and RegExp instances, Objects
	if (a && b && typeof a === "object" && typeof b === "object") {
		// Check Array equality case
		const isArrA = isArray(a);
		const isArrB = isArray(b);

		const arrA = a as [];
		const arrB = b as [];

		if (isArrA !== isArrB) return false;
		if (isArrA && isArrB) {
			const { length } = arrA;
			if (length !== arrB.length) return false;
			for (let i = 0; i < length; i += 1) {
				if (!objectEqual(arrA[i], arrB[i])) return false;
			}

			return true;
		}

		// Check Date instance equality case
		const isDateA = a instanceof Date;
		const isDateB = b instanceof Date;

		const dateA = a as Date;
		const dateB = b as Date;

		if (isDateA !== isDateB) return false;
		if (isDateA && isDateB) return dateA.getTime() === dateB.getTime();

		// Check RegExp instance equality case
		const regexpA = a instanceof RegExp;
		const regexpB = b instanceof RegExp;
		if (regexpA !== regexpB) return false;
		if (regexpA && regexpB) return a.toString() === b.toString();

		// Check Object equality case
		const keys = keyList(a);
		const { length } = keys;
		if (length !== keyList(b).length) return false;
		for (let i = 0; i < length; i += 1) {
			const key = keys[i];
			if (!hasProp.call(b, key)) return false;
			if (!objectEqual(a[key as keyof typeof a], b[key as keyof typeof b])) {
				return false;
			}
		}

		return true;
	}

	// Return negative if none of the above cases where met
	return false;
};

const replaceQueryQuestionmarkWithAmpersand = (query: string): string => {
	const firstQuestionMarkIndex = query.indexOf("?");

	return (
		query.substring(0, firstQuestionMarkIndex + 1) +
		query.substring(firstQuestionMarkIndex + 1).replace(/\?/g, "&")
	);
};

const multiValueFilterQuery = (
	filterParam: string,
	filterObject: string | boolean | Date | CheckBoxState
): QueryParam[] => {
	const query = [] as QueryParam[];

	if (typeof filterObject === "string") {
		filterObject = { [filterObject]: true };
	}

	if (Array.isArray(filterObject)) {
		filterObject.forEach((value: string) => {
			query.push(setQueryParam(filterParam, value));
		});

		return query;
	}

	Object.entries(filterObject || {}).forEach(
		([key, value]: [string, boolean]) =>
			value ? query.push(setQueryParam(filterParam, key)) : {}
	);

	return query;
};

const arrayNotEmpty = (array: unknown[]): boolean =>
	Array.isArray(array) && array.length > 0;

const singleParamSort = (prop: string): unknown => {
	let property = prop;
	let sortOrder = 1;

	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}

	return (a: {}, b: {}) => {
		const aValue = a[property as keyof typeof a];
		const bValue = b[property as keyof typeof b];

		const { CREATED, UPDATED } = sortFields;
		const reverseOrder = property === CREATED || property === UPDATED;

		let result = 0;

		if (aValue < bValue) result = reverseOrder ? 1 : -1;
		if (aValue > bValue) result = reverseOrder ? -1 : 1;

		return result * sortOrder;
	};
};

const dynamicSort =
	(...args: (string | number | {})[]) =>
	(obj1: {}, obj2: {}): number => {
		let result = 0;

		for (let i = 0; i < args.length; i++) {
			result = (singleParamSort(args[i] as string) as (a: {}, b: {}) => number)(
				obj1,
				obj2
			);

			if (result !== 0) return result;
		}

		return result;
	};

const separateNumericNonNumeric = (str: string): Record<string, string> => {
	const match = str.match(/^(\d+(?:\.\d+)?)(\D.*)$/);

	if (match) {
		return {
			numeric: match[1],
			nonNumeric: match[2]
		};
	}

	return {
		numeric: "",
		nonNumeric: str
	};
};

const sortBrowsers = (browsers: Static[]): Static[] => {
	return browsers.sort((a, b) => {
		const isALatest = a.name.includes("Latest");
		const isBLatest = b.name.includes("Latest");

		if (isALatest && isBLatest) {
			return a.name.localeCompare(b.name);
		}
		if (isALatest) return -1;

		if (isBLatest) return 1;

		const [aName, aVersion] = a.name.split(" ").slice(-2);
		const [bName, bVersion] = b.name.split(" ").slice(-2);

		if (aName !== bName) {
			return aName.localeCompare(bName);
		}

		return Number(bVersion) - Number(aVersion);
	});
};

const sortComputeUnits = (computeUnits: Static[]): Static[] =>
	computeUnits.sort((a, b) => {
		return Number(a.value.slice(1)) - Number(b.value.slice(1));
	});

const sortNetworks = (networks: Static[]): Static[] =>
	networks.sort((a, b) => {
		if (a.value === "default") return -1;
		if (b.value === "default") return 1;

		const isAPacket = a.value.includes("packet");
		const isBPacket = b.value.includes("packet");

		if (isAPacket && isBPacket) {
			return (
				Number(separateNumericNonNumeric(a.value).numeric) -
				Number(separateNumericNonNumeric(b.value).numeric)
			);
		}

		if (isAPacket) return -1;
		if (isBPacket) return 1;

		const aParts = separateNumericNonNumeric(a.name);
		const bParts = separateNumericNonNumeric(b.name);

		if (aParts.numeric && bParts.numeric) {
			return Number(aParts.numeric) - Number(bParts.numeric);
		}

		if (aParts.numeric) return -1;
		if (bParts.numeric) return 1;

		return aParts.nonNumeric.localeCompare(bParts.nonNumeric);
	});

const sortAudioFeed = (audioFeed: Static[]): Static[] => {
	return audioFeed.sort((a, b) => {
		if (a.value === "default") return -1;
		if (b.value === "default") return 1;

		const kbpsA = a.value.match(/^(\d+)kbps$/);
		const kbpsB = b.value.match(/^(\d+)kbps$/);

		if (kbpsA && kbpsB) {
			return Number(kbpsB[1]) - Number(kbpsA[1]);
		}
		if (kbpsA) return -1;
		if (kbpsB) return 1;

		const dbA = a.value.match(/^-([\d.]+)db$/);
		const dbB = b.value.match(/^-([\d.]+)db$/);

		if (dbA && dbB) {
			return parseFloat(dbB[0]) - parseFloat(dbA[0]);
		}

		if (dbA) return -1;
		if (dbB) return 1;

		return a.name.localeCompare(b.name);
	});
};

const sortVideoFeed = (videoFeed: Static[]): Static[] => {
	return videoFeed.sort((a, b) => {
		const getResolution = (name: string): string => {
			const resolutionMatch = name.match(/\b\d+p\b/);
			return resolutionMatch ? resolutionMatch[0].replace("p", "") : "";
		};

		const getFPS = (name: string): number | null => {
			const fpsMatch = name.match(/\s@\s(\d+)FPS/);
			return fpsMatch ? Number(fpsMatch[1]) : null;
		};

		if (a.value === "default") return -1;
		if (b.value === "default") return 1;

		const aResolution = getResolution(a.name);
		const bResolution = getResolution(b.name);

		const aFPS = getFPS(a.name);
		const bFPS = getFPS(b.name);

		if (aResolution !== bResolution) {
			return Number(bResolution) - Number(aResolution);
		}

		if (aFPS !== null && bFPS !== null) {
			return aFPS - bFPS;
		}

		if (aFPS !== null) return -1;

		if (bFPS !== null) return 1;

		return a.name.localeCompare(b.name);
	});
};

const sortTestDuration = (testDuration: Static[]): Static[] => {
	return testDuration.sort((a, b) => {
		const getMinutes = (value: string): number => {
			if (value.endsWith("m")) {
				return parseInt(value, 10);
			}

			if (value.endsWith("h")) {
				return parseInt(value, 10) * 60;
			}

			return 0;
		};

		return getMinutes(a.value) - getMinutes(b.value);
	});
};

export {
	arrayNotEmpty,
	arraysEqual,
	dateToUnixTime,
	dynamicSort,
	initializeKeys,
	initializeStateFromFilters,
	initializeStateFromQParams,
	isDate,
	isNumeric,
	formatDateTime,
	formatObjectKey,
	formatTableValue,
	multiValueFilterQuery,
	objectEmpty,
	objectEqual,
	objectValues,
	replaceQueryQuestionmarkWithAmpersand,
	snakeToCamel,
	sortBrowsers,
	sortComputeUnits,
	sortNetworks,
	sortAudioFeed,
	sortVideoFeed,
	sortTestDuration
};
