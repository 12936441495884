import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import qs from "query-string";

import { authorize } from "../../api/auth";

import { config } from "../../config/config";

import { getCurrentAccount } from "../../redux/slices/accounts";
import { IStoreState } from "../../redux/slices";
import { checkAuthStatus } from "../../redux/slices/auth";
import { AppDispatch } from "../../redux/store";

import { routes } from "../../router/routes";

import { Account } from "../../types/account";

import { deleteSession } from "../../utils/session";
import { authKeys, queryKeys } from "../../constants";

const Authorize = (): null => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const codeVerifier = window.sessionStorage.getItem(authKeys.CODE_VERIFIER);

	const accessToken = useSelector(
		({ persistedStorage }: IStoreState) => persistedStorage.access_token
	);

	useEffect(() => {
		const authorizeAccount = async (): Promise<void> => {
			const relayedParams = qs.parse(location.search);

			const params = {
				client_id: relayedParams.client_id,
				grant_type: queryKeys.AUTHORIZATION_CODE,
				redirect_uri: relayedParams.redirect_uri,
				code: relayedParams.code,
				code_verifier: codeVerifier
			};

			try {
				await authorize(qs.stringify(params));
			} catch (e) {
				navigate(routes.LOG_IN);
			}
		};

		authorizeAccount();
	}, []);

	useEffect(() => {
		if (!accessToken) return;

		const fetchCurrentAccount = async (): Promise<void> => {
			try {
				const account = await dispatch(getCurrentAccount(navigate));

				if ((account.payload as Account).superuser) {
					dispatch(
						checkAuthStatus({
							navigate,
							authState: true,
							nextRoute: routes.HOME
						})
					);

					return;
				}

				deleteSession();
				dispatch(checkAuthStatus({ navigate, authState: false }));
				window.location.href = config.appURL || "";
			} catch {
				deleteSession();
				dispatch(checkAuthStatus({ navigate, authState: false }));
				window.location.href = config.appURL || "";
			}
		};

		fetchCurrentAccount();
	}, [accessToken]);

	return null;
};

export default Authorize;
