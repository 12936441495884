import { AxiosResponse } from "axios";

import { NavigateFunction } from "react-router-dom";

import { APIError, RequestOptions } from "../types/api";

import { alertError, api } from "./Api";

const handleError = (
	error: APIError,
	suppressNotification: boolean,
	// eslint-disable-next-line @typescript-eslint/ban-types
	reject: Function
): APIError => {
	switch (true) {
		case suppressNotification:
			return reject(error);
		default: {
			alertError(error);
			return reject(error);
		}
	}
};

const READ = <T>(
	h: NavigateFunction,
	route: string,
	options: RequestOptions = {}
): Promise<T> => {
	const { suppressNotification = false } = options;
	return new Promise((resolve, reject) => {
		api.GET(h, route).then(
			(response: AxiosResponse) => {
				resolve(response.data || {});
			},
			error => {
				handleError(error, suppressNotification, reject);
			}
		);
	});
};

const crud = {
	READ
};

export default crud;
