import axios, { AxiosInstance } from "axios";

import { config } from "../config/config";

const authRest: AxiosInstance = axios.create({
	baseURL: config.authURL,
	timeout: 15000,
	headers: {
		Authorization: `Basic ${config.basicToken}`
	}
});

const apiRest: AxiosInstance = axios.create({
	baseURL: config.apiURL,
	timeout: 15000
});

export { apiRest, authRest };
