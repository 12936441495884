import { FC, Fragment, PropsWithChildren, ReactNode, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../redux/store";
import { fetchAllStatics } from "../redux/slices/ui";
import { IStoreState } from "../redux/slices";

import { routes } from "./routes";

interface IProps {
	children: PropsWithChildren<ReactNode>;
}

const PrivateRoute: FC<IProps> = ({ children }): JSX.Element => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const loggedIn = useSelector(
		({ authReducer }: IStoreState) => authReducer.loggedIn
	);

	useEffect(() => {
		if (!loggedIn) return;

		dispatch(fetchAllStatics(navigate));
	}, [loggedIn]);

	return loggedIn ? (
		<Fragment>{children}</Fragment>
	) : (
		<Navigate to={routes.LOG_IN} replace />
	);
};

export default PrivateRoute;
