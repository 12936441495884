import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Authorize from "../components/authorize/Authorize";
import Iframe from "../components/iframe/Iframe";

import Layout from "../HOC/layout/Layout";

import Accounts from "../templates/accounts/Accounts";
import Dashboard from "../templates/dashboard/Dashboard";
import Groups from "../templates/groups/Groups";
import Participants from "../templates/participants/Participants";
import Projects from "../templates/projects/Projects";
import Runs from "../templates/runs/Runs";
import Subscriptions from "../templates/subscriptions/Subscriptions";
import Tests from "../templates/tests/Tests";

import { createRoute } from "../utils/request";

import PrivateRoute from "./PrivateRoute";

import { routes } from "./routes";

const AppRouter: FC = (): JSX.Element => (
	<Routes>
		<Route
			path={createRoute(false, routes.LOG_IN)}
			element={
				<Iframe
					route={createRoute(
						true,
						routes.AUTH,
						routes.AUTHORIZE,
						routes.LOG_IN
					)}
				/>
			}
		/>

		<Route path={routes.AUTHORIZE} element={<Authorize />} />

		<Route
			path={routes.HOME}
			element={
				<PrivateRoute>
					<Layout>
						<Dashboard />
					</Layout>
				</PrivateRoute>
			}
		/>

		<Route
			path={routes.ACCOUNTS}
			element={
				<PrivateRoute>
					<Layout>
						<Accounts />
					</Layout>
				</PrivateRoute>
			}
		/>

		<Route
			path={routes.PARTICIPANTS}
			element={
				<PrivateRoute>
					<Layout>
						<Participants />
					</Layout>
				</PrivateRoute>
			}
		/>

		<Route
			path={routes.PROJECTS}
			element={
				<PrivateRoute>
					<Layout>
						<Projects />
					</Layout>
				</PrivateRoute>
			}
		/>

		<Route
			path={routes.TESTS}
			element={
				<PrivateRoute>
					<Layout>
						<Tests />
					</Layout>
				</PrivateRoute>
			}
		/>

		<Route
			path={routes.SUBSCRIPTIONS}
			element={
				<PrivateRoute>
					<Layout>
						<Subscriptions />
					</Layout>
				</PrivateRoute>
			}
		/>

		<Route
			path={routes.RUNS}
			element={
				<PrivateRoute>
					<Layout>
						<Runs />
					</Layout>
				</PrivateRoute>
			}
		/>

		<Route
			path={routes.GROUPS}
			element={
				<PrivateRoute>
					<Layout>
						<Groups />
					</Layout>
				</PrivateRoute>
			}
		/>

		<Route
			path={routes.HOME}
			element={<Navigate to={routes.LOG_IN} replace />}
		/>
	</Routes>
);

export default AppRouter;
