import { useSelector } from "react-redux";

import AutoFillTable from "../../components/autoFillTable/AutoFillTable";
import { columnNames, tableName } from "../../constants";

import { useAutoFillTableLogic } from "../../hooks/useAutoFillTableLogic";

import {
	getAllParticipants,
	getGroupParticipants,
	getParticipant,
	getTestParticipants
} from "../../redux/slices/participants";
import { IStoreState } from "../../redux/slices";
import { routes } from "../../router/routes";

import { ConfigFilters } from "../../types/filters";

import { createRoute } from "../../utils/request";

const Participants = () => {
	const { participantData, isFetching } = useSelector(
		(state: IStoreState) => state.participantReducer
	);

	const { classificators } = useSelector(
		(state: IStoreState) => state.uiReducer
	);

	const configFilters = [
		{
			name: "id",
			elementType: "input"
		},
		{
			name: "name",
			elementType: "input"
		},
		{
			name: "countFrom",
			elementType: "input"
		},
		{
			name: "countTo",
			elementType: "input"
		},
		{
			name: "computeUnit",
			elementType: "multiselect",
			label: "Compute Unit",
			options: [
				...classificators.compute_unit.map(item => ({
					label: item.name,
					key: item.value
				}))
			],
			selected: ""
		},
		{
			name: "browser",
			elementType: "multiselect",
			label: "Browser",
			options: [
				...classificators.browser.map(item => ({
					label: item.name,
					key: item.value
				}))
			],
			selected: ""
		},
		{
			name: "network",
			elementType: "multiselect",
			label: "Network",
			options: [
				...classificators.network.map(item => ({
					label: item.name,
					key: item.value
				}))
			],
			selected: ""
		},
		{
			name: "location",
			elementType: "multiselect",
			label: "Location",
			options: [
				...classificators.location.map(item => ({
					label: item.name,
					key: item.value
				}))
			],
			selected: ""
		},
		{
			name: "videoFeed",
			elementType: "multiselect",
			label: "Video Feed",
			options: [
				...classificators.video_feed.map(item => ({
					label: item.name,
					key: item.value
				}))
			],
			selected: ""
		},
		{
			name: "audioFeed",
			elementType: "multiselect",
			label: "Audio Feed",
			options: [
				...classificators.audio_feed.map(item => ({
					label: item.name,
					key: item.value
				}))
			],
			selected: ""
		},
		{
			name: "hasGroup",
			elementType: "radio",
			label: "Has group",
			options: [
				{ label: "Yes", key: "true" },
				{ label: "No", key: "false" },
				{ label: "All", key: "" }
			],
			selected: ""
		},
		{
			name: "recordAudio",
			elementType: "radio",
			label: "Record audio",
			options: [
				{ label: "Yes", key: "true" },
				{ label: "No", key: "false" },
				{ label: "All", key: "" }
			],
			selected: ""
		}
	];

	const { GROUP_ID, TEST_ID } = columnNames;

	const routeGroups = createRoute(true, routes.GROUPS);
	const routeTests = createRoute(true, routes.TESTS);

	const linkColumns = [
		{ name: GROUP_ID, route: routeGroups },
		{ name: TEST_ID, route: routeTests }
	];

	const qpResolver = [
		{ paramMatch: "group_id", fn: getGroupParticipants },
		{ paramMatch: "test_id", fn: getTestParticipants }
	];

	const {
		columnFilters,
		colNames,
		handleFetchData,
		init,
		onPageChange,
		pager,
		setColumnFilters,
		setDataFilters
	} = useAutoFillTableLogic(
		[],
		[],
		getAllParticipants,
		getParticipant,
		{} as { key: string; columns: string[] },
		[],
		tableName.PARTICIPANTS,
		qpResolver,
		linkColumns
	);

	return (
		<div className="participants">
			<h1>Participants</h1>

			<AutoFillTable
				columnFilters={columnFilters}
				tableColumnNames={colNames}
				data={participantData}
				filters={configFilters as ConfigFilters[]}
				handleFetchData={handleFetchData}
				init={init}
				loading={isFetching}
				nestedData={{} as { key: string; columns: string[] }}
				onPageChange={onPageChange}
				pager={pager}
				setDataFilters={setDataFilters}
				setColumnFilters={setColumnFilters}
				tableName={tableName.PARTICIPANTS}
				linkColumns={linkColumns}
			/>
		</div>
	);
};

export default Participants;
