import { createReduxEnhancer } from "@sentry/react";
import { configureStore } from "@reduxjs/toolkit";

import persistedReducer from "./slices";
import { config } from "../config/config";

const sentryReduxEnhancer = createReduxEnhancer();

export const store = configureStore({
	reducer: persistedReducer,
	devTools: config.env !== "prod",
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({ serializableCheck: false }),
	enhancers: getDefaultEnhancers =>
		getDefaultEnhancers().concat(sentryReduxEnhancer)
});

export type AppDispatch = typeof store.dispatch;
