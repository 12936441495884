import { FC } from "react";
import { useSelector } from "react-redux";

import AutoFillTable from "../../components/autoFillTable/AutoFillTable";

import { columnNames, tableName } from "../../constants";

import { useAutoFillTableLogic } from "../../hooks/useAutoFillTableLogic";

import { getAllRuns, getRun, getTestRuns } from "../../redux/slices/runs";
import { IStoreState } from "../../redux/slices";

import { routes } from "../../router/routes";

import { ConfigFilters } from "../../types/filters";

import { createRoute } from "../../utils/request";

const Runs: FC = (): JSX.Element => {
	const { runData, isFetching } = useSelector(
		(state: IStoreState) => state.runReducer
	);

	const configFilters = [
		{
			name: "id",
			elementType: "input"
		},
		{
			name: "launchingAccountId",
			elementType: "input"
		},
		{
			name: "testId",
			elementType: "input"
		},
		{
			name: "testName",
			elementType: "input"
		},
		{
			name: "participantTimeoutFrom",
			elementType: "input"
		},
		{
			name: "participantTimeoutTo",
			elementType: "input"
		},
		{
			name: "startIntervalFrom",
			elementType: "input"
		},
		{
			name: "startIntervalTo",
			elementType: "input"
		},
		{
			name: "executionFinishedFrom",
			elementType: "date"
		},
		{
			name: "executionFinishedTo",
			elementType: "date"
		},
		{
			name: "executionStartedFrom",
			elementType: "date"
		},
		{
			name: "executionStartedTo",
			elementType: "date"
		},
		{
			name: "processingFinishedFrom",
			elementType: "date"
		},
		{
			name: "processingFinishedTo",
			elementType: "date"
		},
		{
			name: "processingStartedFrom",
			elementType: "date"
		},
		{
			name: "processingStartedTo",
			elementType: "date"
		},

		{
			name: "incrementStrategy",
			elementType: "multiselect",
			label: "Increment Strategy",
			options: [
				{ label: "Linear Participant", key: "linear" },
				{ label: "Linear Group", key: "linear_group" },
				{ label: "Random Participant", key: "random" },
				{ label: "Random Group", key: "random_group" }
			],
			selected: ""
		},
		{
			name: "metricStatus",
			elementType: "multiselect",
			label: "Metric status",
			options: [
				{ label: "Available", key: "available" },
				{ label: "None", key: "none" },
				{ label: "Requested", key: "requested" },
				{ label: "Calculating", key: "calculating" },
				{ label: "Calculation Error", key: "calculation-error" },
				{ label: "Calculation Timeout", key: "calculation-timeout" }
			],
			selected: ""
		},
		{
			name: "mosStatus",
			elementType: "multiselect",
			label: "Mos status",
			options: [
				{ label: "Available", key: "available" },
				{ label: "None", key: "none" },
				{ label: "Requested", key: "requested" },
				{ label: "Calculating", key: "calculating" },
				{ label: "Calculation Error", key: "calculation-error" },
				{ label: "Calculation Timeout", key: "calculation-timeout" }
			],
			selected: ""
		},
		{
			name: "status",
			elementType: "multiselect",
			label: "Status",
			options: [
				{ label: "Aborted", key: "aborted" },
				{ label: "AWS error", key: "aws-error" },
				{ label: "Database error", key: "db-error" },
				{ label: "Done", key: "done" },
				{ label: "Initializing", key: "initializing" },
				{ label: "Insufficient resources", key: "insufficient-resources" },
				{ label: "No users", key: "no-users" },
				{ label: "Pending", key: "pending" },
				{ label: "Running", key: "running" },
				{ label: "Server error", key: "server-error" },
				{ label: "Stopping", key: "stopping" },
				{ label: "Timeout exceeded", key: "timeout-exceeded" },
				{ label: "Waiting results", key: "waiting-results" }
			],
			selected: ""
		},
		{
			name: "testMode",
			elementType: "multiselect",
			label: "Test Mode",
			options: [
				{ label: "Load", key: "load" },
				{ label: "Performance", key: "performance" },
				{ label: "Session Recording", key: "session-record" }
			],
			selected: ""
		},
		{
			name: "started",
			elementType: "radio",
			label: "Started",
			options: [
				{ label: "True", key: "true" },
				{ label: "False", key: "false" },
				{ label: "All", key: "" }
			],
			selected: ""
		},
		{
			name: "finished",
			elementType: "radio",
			label: "Finished",
			options: [
				{ label: "True", key: "true" },
				{ label: "False", key: "false" },
				{ label: "All", key: "" }
			],
			selected: ""
		},
		{
			name: "mosTest",
			elementType: "radio",
			label: "Mos test",
			options: [
				{ label: "True", key: "true" },
				{ label: "False", key: "false" },
				{ label: "All", key: "" }
			],
			selected: ""
		}
	];

	const { TEST_ID } = columnNames;

	const route = createRoute(true, routes.TESTS);

	const linkColumns = [{ name: TEST_ID, route: route }];
	const qpResolver = [{ paramMatch: "test_id", fn: getTestRuns }];

	const customColumns = [columnNames.MOS_TEST];

	const {
		columnFilters,
		colNames,
		handleFetchData,
		init,
		onPageChange,
		pager,
		setColumnFilters,
		setDataFilters
	} = useAutoFillTableLogic(
		customColumns,
		[],
		getAllRuns,
		getRun,
		{} as { key: string; columns: string[] },
		[],
		tableName.RUNS,
		qpResolver,
		linkColumns
	);

	return (
		<div className="runs">
			<h1>Runs</h1>

			<AutoFillTable
				columnFilters={columnFilters}
				tableColumnNames={colNames}
				data={runData}
				filters={configFilters as ConfigFilters[]}
				handleFetchData={handleFetchData}
				init={init}
				loading={isFetching}
				nestedData={{} as { key: string; columns: string[] }}
				onPageChange={onPageChange}
				pager={pager}
				setDataFilters={setDataFilters}
				setColumnFilters={setColumnFilters}
				tableName={tableName.RUNS}
				linkColumns={linkColumns}
			/>
		</div>
	);
};

export default Runs;
