import { FC, ReactNode, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Icon from "./components/icon/Icon";

import { toastAlert } from "./constants";

import { AppDispatch } from "./redux/store";
// NEVER PUT "validSession" UNDER "checkAuthStatus". IT WILL BREAK THE APP!!!
import { validSession } from "./utils/session";
import { checkAuthStatus } from "./redux/slices/auth";

import { routes } from "./router/routes";
import AppRouter from "./router";

type CloseButtonProps = {
	closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const renderCloseButton = ({ closeToast }: CloseButtonProps): ReactNode => (
	<Icon onClick={closeToast} className="alert-close" icon="alert-close" />
);

const App: FC = (): JSX.Element | null => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const location = useLocation();

	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		validSession(navigate).then(isLoggedIn => {
			const { pathname } = location;
			const { HOME, LOG_IN } = routes;

			const route = isLoggedIn && pathname === LOG_IN ? HOME : location;

			dispatch(
				checkAuthStatus({
					navigate,
					authState: isLoggedIn,
					nextRoute: route as string
				})
			);

			setInitialized(true);
		});
	}, []);

	if (!initialized) return null;

	return (
		<div className="app">
			<ToastContainer
				className="alert-container"
				toastClassName="alert-box"
				progressClassName="alert-progress-bar"
				autoClose={toastAlert.AUTO_CLOSE_TIME}
				closeButton={renderCloseButton}
				newestOnTop
			/>

			<AppRouter />
		</div>
	);
};

export default App;
