import logoGradient from "./logoGradient.svg";
import logoHeader from "./headerLogo.svg";

const media = {
	loadero: {
		gradient: logoGradient,
		header: logoHeader
	}
};

export default media;
