const routes = {
	ACCOUNTS: "/accounts",
	AUTH: "/auth",
	AUTHORIZE: "/authorize",
	AUTH_TOKEN: "/auth/token",
	CURRENT: "/current",
	GROUPS: "/groups",
	HOME: "/",
	LOG_IN: "/login",
	LOG_OUT: "/logout",
	PARTICIPANTS: "/participants",
	PROJECTS: "/projects",
	RUNS: "/runs",
	SUBSCRIPTIONS: "/subscriptions",
	TESTS: "/tests",
	V1ADMIN: "/v1admin",
	V2: "/v2",
	STATICS: "/statics"
};

export { routes };
