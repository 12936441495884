import { FC } from "react";
import { useSelector } from "react-redux";

import AutoFillTable from "../../components/autoFillTable/AutoFillTable";

import { columnNames, tableName } from "../../constants";

import { useAutoFillTableLogic } from "../../hooks/useAutoFillTableLogic";

import { getAllAccounts, getAccount } from "../../redux/slices/accounts";
import { IStoreState } from "../../redux/slices";

import { routes } from "../../router/routes";

import { ConfigFilters } from "../../types/filters";

import { createRoute } from "../../utils/request";

const Accounts: FC = (): JSX.Element => {
	const { accountData, isFetching } = useSelector(
		(state: IStoreState) => state.accountReducer
	);

	const configFilters = [
		{
			name: "id",
			elementType: "input"
		},
		{
			name: "firstName",
			elementType: "input"
		},
		{
			name: "lastName",
			elementType: "input"
		},
		{
			name: "email",
			elementType: "input"
		},
		{
			name: "lastLoginFrom",
			elementType: "date"
		},
		{
			name: "lastLoginTo",
			elementType: "date"
		},
		{
			name: "superUser",
			elementType: "radio",
			label: "Super User",
			options: [
				{ label: "All users", key: "" },
				{ label: "Yes", key: "true" },
				{ label: "No", key: "false" }
			],
			selected: ""
		}
	];

	const {
		PROJECTS,
		SUPER_USER,
		NEW_TEST_BUILDER,
		TEST_BUILDER_TOUR_COMPLETED,
		METADATA
	} = columnNames;

	const route = createRoute(true, routes.PROJECTS);

	const customColumns = [SUPER_USER];
	const disabledColumns = [TEST_BUILDER_TOUR_COMPLETED, NEW_TEST_BUILDER];
	const linkColumns = [{ name: PROJECTS, route: route }];
	const nestedData = {
		key: METADATA,
		columns: [NEW_TEST_BUILDER, TEST_BUILDER_TOUR_COMPLETED]
	};
	const suppressColumns = [METADATA];

	const {
		columnFilters,
		colNames,
		handleFetchData,
		init,
		onPageChange,
		pager,
		setColumnFilters,
		setDataFilters
	} = useAutoFillTableLogic(
		customColumns,
		disabledColumns,
		getAllAccounts,
		getAccount,
		nestedData,
		suppressColumns,
		tableName.ACCOUNTS,
		[],
		linkColumns
	);

	return (
		<div className="accounts">
			<h1>Accounts</h1>

			<AutoFillTable
				columnFilters={columnFilters}
				tableColumnNames={colNames}
				data={accountData}
				filters={configFilters as ConfigFilters[]}
				handleFetchData={handleFetchData}
				init={init}
				loading={isFetching}
				nestedData={nestedData}
				onPageChange={onPageChange}
				pager={pager}
				setDataFilters={setDataFilters}
				setColumnFilters={setColumnFilters}
				tableName={tableName.ACCOUNTS}
				linkColumns={linkColumns}
			/>
		</div>
	);
};

export default Accounts;
