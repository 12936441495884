import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";

import moment from "moment";

import crud from "../../api/crud";

import { queryKeys } from "../../constants/request";

import { routes } from "../../router/routes";

import { Account } from "../../types/account";
import {
	APIError,
	RejectWithValue,
	ResponseData,
	ResponseDataWithPaging,
	getAllParams,
	getByIDParams
} from "../../types/api";

import {
	appendSearchQuery,
	createQuery,
	createRoute,
	getOffset,
	paginationFallback,
	setQueryParam
} from "../../utils/request";

interface IAccountReducer {
	accountData: ResponseDataWithPaging | ResponseData | null;
	currentAccount: Account | null;
	isFetching: boolean;
	errorMessage: APIError | string | null;
}

const initialState: IAccountReducer = {
	accountData: null,
	currentAccount: null,
	isFetching: false,
	errorMessage: null
};

const {
	ID,
	FILTER: { ACCOUNT },
	LIMIT,
	OFFSET
} = queryKeys;

const {
	FIRST_NAME,
	EMAIL,
	LAST_LOGIN_FROM,
	LAST_LOGIN_TO,
	LAST_NAME,
	SUPER_USER
} = ACCOUNT;

export const getAllAccounts = createAsyncThunk(
	"accounts/getAllAccounts",
	async (
		params: getAllParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, queryParams } = params;

		const { filters, pagination } = queryParams || {};
		const { firstName, email, lastLoginFrom, lastName, superUser } =
			filters || {};
		let { lastLoginTo } = filters || {};
		const { limit, page } = pagination || {};

		const searchQuery = createQuery(
			setQueryParam(queryKeys.PAGE, page),
			setQueryParam(queryKeys.LIMIT, limit),
			setQueryParam(FIRST_NAME, firstName),
			setQueryParam(LAST_NAME, lastName),
			setQueryParam(SUPER_USER, superUser),
			setQueryParam(EMAIL, email),
			setQueryParam(LAST_LOGIN_FROM, lastLoginFrom),
			setQueryParam(LAST_LOGIN_TO, lastLoginTo)
		);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(true, routes.V1ADMIN, routes.ACCOUNTS);

		if (lastLoginTo) {
			lastLoginTo = new Date(
				moment(lastLoginTo as string)
					.endOf("day")
					.toDate()
			);
		}

		route += createQuery(
			setQueryParam(EMAIL, email),
			setQueryParam(FIRST_NAME, firstName),
			setQueryParam(LAST_NAME, lastName),
			setQueryParam(
				LAST_LOGIN_FROM,
				lastLoginFrom && moment.utc(lastLoginFrom as string).unix()
			),
			setQueryParam(
				LAST_LOGIN_TO,
				lastLoginTo && moment.utc(lastLoginTo).unix()
			),
			setQueryParam(LIMIT, limit),
			setQueryParam(OFFSET, getOffset(limit, page || 1)),
			setQueryParam(SUPER_USER, superUser)
		);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllAccounts
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getAccount = createAsyncThunk(
	"accounts/getAccount",
	async (
		params: getByIDParams,
		{ rejectWithValue }
	): Promise<Account | RejectWithValue> => {
		const { navigate, location, id } = params;

		const searchQuery = createQuery(
			setQueryParam(ID, Number(id)),
			setQueryParam(queryKeys.PAGE, 1),
			setQueryParam(queryKeys.LIMIT, 20)
		);
		appendSearchQuery(navigate, location, searchQuery);

		const route = createRoute(true, routes.V1ADMIN, routes.ACCOUNTS, id);

		const options = {
			suppressNotification: true
		};

		try {
			const response = await crud.READ<Account>(navigate, route, options);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getCurrentAccount = createAsyncThunk(
	"accounts/getCurrentAccount",
	async (navigate: NavigateFunction) => {
		const route = createRoute(true, routes.V2, routes.ACCOUNTS, routes.CURRENT);

		try {
			const response = await crud.READ<Account>(navigate, route);

			return response;
		} catch (error) {
			return error as APIError;
		}
	}
);

const accountsSlice = createSlice({
	name: "accounts",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAllAccounts.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getAllAccounts.fulfilled, (state, action) => {
				state.accountData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getAllAccounts.rejected, (state, action) => {
				state.accountData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
				state.currentAccount = null;
			})
			.addCase(getAccount.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getAccount.fulfilled, (state, action) => {
				state.accountData = { results: [action.payload as Account] };
				state.isFetching = false;
			})
			.addCase(getAccount.rejected, (state, action) => {
				state.accountData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
				state.currentAccount = null;
			})
			.addCase(getCurrentAccount.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getCurrentAccount.fulfilled, (state, action) => {
				state.currentAccount = action.payload as Account;
				state.isFetching = false;
			})
			.addCase(getCurrentAccount.rejected, (state, action) => {
				state.accountData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
				state.currentAccount = null;
			});
	}
});

export default accountsSlice.reducer;
