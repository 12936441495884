import { FC, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import qs from "query-string";

import { alertError } from "../../api/Api";

import { authKeys, scopes } from "../../constants/auth";

import { config, getUrl } from "../../config/config";

import { routes } from "../../router/routes";

import { createQuery, setQueryParam } from "../../utils/request";

import { generateCodeChallenge } from "./helpers";
import { queryKeys } from "../../constants";

interface IProps {
	redirectURL?: string;
	route: string;
}

const Iframe: FC<IProps> = ({ redirectURL, route }): JSX.Element | null => {
	const navigate = useNavigate();
	const location = useLocation();

	const [constructedURL, setConstructedURL] = useState<string>("");

	const { CODE, EMAIL, FORGOT_PASSWORD, PRESERVED } = queryKeys;

	useEffect(() => {
		generateCodeChallenge().then(
			codeChallenge => {
				const {
					CLIENT_ID,
					CODE_CHALLENGE,
					CODE_CHALLENGE_METHOD,
					NOREGISTER,
					REDIRECT_URI,
					RESPONSE_TYPE,
					SCOPE,
					STATE
				} = authKeys;

				let url = config.authURL as string;
				url += route;

				const queryParams = [
					setQueryParam(CLIENT_ID, config.clientID),
					setQueryParam(RESPONSE_TYPE, CODE),
					setQueryParam(NOREGISTER, true),
					setQueryParam(SCOPE, scopes.join(" ")),
					setQueryParam(STATE, PRESERVED),
					setQueryParam(CODE_CHALLENGE, codeChallenge),
					setQueryParam(CODE_CHALLENGE_METHOD, "S256"),
					setQueryParam(
						REDIRECT_URI,
						`${getUrl()}${redirectURL ? redirectURL : routes.AUTHORIZE}`
					)
				];

				const params = qs.parse(location.search);

				if (
					location.pathname.includes(routes.LOG_IN) &&
					FORGOT_PASSWORD in params &&
					EMAIL in params
				) {
					toast.info(`Password reset link was sent to: ${params.email}`, {
						autoClose: false
					});
				}

				url += createQuery(...queryParams);

				// Pass through any custom query params to BE
				const queryString = location.search;
				url += queryString ? `&${queryString.slice(1)}` : "";

				setConstructedURL(url);

				// Remove query params relayed from BE
				navigate(location.pathname, { replace: true });
			},
			error => {
				alertError(error);
				navigate(routes.LOG_IN);
			}
		);
	}, []);

	return <iframe className="iframe" src={constructedURL} frameBorder="0" />;
};

export default Iframe;
