import { AxiosPromise } from "axios";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";

import { APIError, RequestConfig } from "../types/api";

import { getRequestConfig } from "../utils/request";
import { checkAuthStatus } from "../utils/session";

import { apiRest, authRest } from "./config";

const authDELETE = (route: string, config: RequestConfig): AxiosPromise =>
	authRest.delete(route, config);

const authGET = (route: string, config?: RequestConfig): AxiosPromise =>
	authRest.get(route, config);

const authPOST = (
	route: string,
	data: unknown,
	config?: RequestConfig
): AxiosPromise => authRest.post(route, data, config);

const authPUT = (
	route: string,
	data: unknown,
	config?: RequestConfig
): AxiosPromise => authRest.put(route, data, config);

const GET = (navigate: NavigateFunction, route: string): AxiosPromise<void> =>
	new Promise((resolve, reject) => {
		checkAuthStatus(navigate).then(
			() => {
				const config = getRequestConfig();
				resolve(apiRest.get(route, config));
			},
			() => {
				reject();
			}
		);
	});

const alertError = (error: APIError): void => {
	const { response } = error || {};
	const message = response?.data?.error;

	if (response && (response.status === 409 || response.status === 422)) {
		return;
	}

	toast.error(message || "An unexpected error occurred");
};

const api = { GET };

const auth = { DELETE: authDELETE, GET: authGET, POST: authPOST, PUT: authPUT };

export { alertError, api, auth };
