import { authKeys } from "../../constants";

const generateRandomString = (length = 20): string => {
	let text = "";
	const possible =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	for (let i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}

	return text;
};

const generateCodeChallenge = async (): Promise<string> => {
	const codeVerifier = generateRandomString();

	window.sessionStorage.setItem(authKeys.CODE_VERIFIER, codeVerifier);

	const digest = await crypto.subtle.digest(
		"SHA-256",
		new TextEncoder().encode(codeVerifier)
	);

	return window
		.btoa(String.fromCharCode(...new Uint8Array(digest)))
		.replace(/=/g, "")
		.replace(/\+/g, "-")
		.replace(/\//g, "_");
};

export { generateCodeChallenge };
