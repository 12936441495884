import { StorageKey } from "../types/persistedStorage";

const authKeys: { [key: string]: StorageKey } = {
	ACCESS_TOKEN: "access_token",
	CLIENT_CREDENTIALS: "client_credentials",
	CLIENT_ID: "client_id",
	CODE_CHALLENGE: "code_challenge",
	CODE_CHALLENGE_METHOD: "code_challenge_method",
	CODE_VERIFIER: "code_verifier",
	EXPIRES_AT: "expires_at",
	GRANT_TYPE: "grant_type",
	LOADERO_SUPERUSER: "loadero_superuser",
	NOREGISTER: "noregister",
	REDIRECT_URI: "redirect_uri",
	REFRESH_TOKEN: "refresh_token",
	REFRESH_TOKEN_PENDING: "refresh_token_pending",
	REFRESH_TOKEN_RESOLVED: "refresh_token_resolved",
	RESPONSE_TYPE: "response_type",
	SCOPE: "scope",
	SCOPES: "scopes",
	STATE: "state",
	TOKEN_TYPE: "token_type"
};

const scopes = [
	"ACCOUNTS_READ_ALL",
	"ACCOUNT_CREATE",
	"ACCOUNT_DELETE",
	"ACCOUNT_EMAIL_VERIFY",
	"ACCOUNT_READ",
	"ACCOUNT_UPDATE",
	"ACCOUNT_AVATAR_DELETE",
	"ACCOUNT_AVATAR_READ",
	"ACCOUNT_AVATAR_UPDATE",
	"ADMIN_ACCOUNT_READ",
	"ADMIN_ACCOUNTS_READ_ALL",
	"ADMIN_GROUP_READ",
	"ADMIN_GROUPS_READ_ALL",
	"ADMIN_PARTICIPANT_READ",
	"ADMIN_PARTICIPANTS_READ_ALL",
	"ADMIN_PROJECT_READ",
	"ADMIN_PROJECTS_READ_ALL",
	"ADMIN_SUBSCRIPTION_READ",
	"ADMIN_SUBSCRIPTIONS_READ_ALL",
	"ADMIN_TEST_RUN_READ",
	"ADMIN_TEST_RUNS_READ_ALL",
	"ADMIN_TEST_READ",
	"ADMIN_TESTS_READ_ALL",
	"ASSERTS_READ_ALL",
	"ASSERT_CREATE",
	"ASSERT_DELETE",
	"ASSERT_READ",
	"ASSERT_UPDATE",
	"AUTH_CHANGE_PASSWORD",
	"AWS_INFO_CREATE",
	"AWS_INFO_DELETE",
	"AWS_INFO_READ",
	"AWS_INFO_UPDATE",
	"BILLING_DELETE",
	"BILLING_READ",
	"BILLING_UPDATE",
	"FILES_READ_ALL",
	"FILE_CREATE",
	"FILE_DELETE",
	"FILE_READ",
	"FILE_UPDATE",
	"GROUPS_READ_ALL",
	"GROUP_CREATE",
	"GROUP_DELETE",
	"GROUP_READ",
	"GROUP_UPDATE",
	"INVITED_MEMBERS_READ_ALL",
	"INVITED_MEMBER_DELETE",
	"INVITED_MEMBER_READ",
	"INVITED_MEMBER_UPDATE",
	"MEMBERS_READ_ALL",
	"MEMBER_CREATE",
	"MEMBER_DELETE",
	"MEMBER_READ",
	"MEMBER_UPDATE",
	"PARTICIPANTS_READ_ALL",
	"PARTICIPANT_CREATE",
	"PARTICIPANT_DELETE",
	"PARTICIPANT_READ",
	"PARTICIPANT_UPDATE",
	"PRECONDITION_BULK_CREATE",
	"PRECONDITIONS_READ_ALL",
	"PRECONDITION_CREATE",
	"PRECONDITION_DELETE",
	"PRECONDITION_READ",
	"PRECONDITION_UPDATE",
	"PROJECTS_READ_ALL",
	"PROJECT_CREATE",
	"PROJECT_DELETE",
	"PROJECT_READ",
	"PROJECT_UPDATE",
	"PROJECT_AVATAR_DELETE",
	"PROJECT_AVATAR_READ",
	"PROJECT_AVATAR_UPDATE",
	"PROJECT_TOKEN_CREATE",
	"PROJECT_TOKEN_DELETE",
	"PROJECT_TOKENS_READ_ALL",
	"RESULTS_READ_ALL",
	"RESULT_READ",
	"RESULT_STATISTICS_READ",
	"STATICS_READ_ALL",
	"STATIC_READ",
	"STATIC_TYPES_READ_ALL",
	"SUBSCRIPTION_CREATE",
	"SUBSCRIPTION_DELETE",
	"SUBSCRIPTION_READ",
	"SUBSCRIPTION_UPDATE",
	"TESTS_READ_ALL",
	"TEST_CREATE",
	"TEST_DELETE",
	"TEST_EXTENDED_CREATE",
	"TEST_EXTENDED_READ",
	"TEST_FAVORITE_CREATE",
	"TEST_FAVORITE_DELETE",
	"TEST_FAVORITES_READ_ALL",
	"TEST_READ",
	"TEST_RUNS_READ_ALL",
	"TEST_RUN_CREATE",
	"TEST_RUN_PARTICIPANTS_READ_ALL",
	"TEST_RUN_PARTICIPANT_READ",
	"TEST_RUN_READ",
	"TEST_RUN_STOP",
	"TEST_UPDATE"
];

export { authKeys, scopes };
