import { FC, PropsWithChildren, ReactNode } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

interface IProps {
	children: PropsWithChildren<ReactNode>;
}

const Layout: FC<IProps> = ({ children }): JSX.Element => (
	<div className="app-root">
		<Header />

		<div className="app-content">{children}</div>

		<Footer />
	</div>
);

export default Layout;
