import { FC } from "react";
import { useSelector } from "react-redux";

import AutoFillTable from "../../components/autoFillTable/AutoFillTable";
import { columnNames, tableName } from "../../constants";

import { useAutoFillTableLogic } from "../../hooks/useAutoFillTableLogic";

import {
	getAllSubscriptions,
	getSubscription
} from "../../redux/slices/subscriptions";

import { IStoreState } from "../../redux/slices";
import { routes } from "../../router/routes";

import { ConfigFilters } from "../../types/filters";

import { createRoute } from "../../utils/request";

const Subscriptions: FC = (): JSX.Element => {
	const { subscriptionData, isFetching } = useSelector(
		(state: IStoreState) => state.subscriptionReducer
	);

	const { classificators } = useSelector(
		(state: IStoreState) => state.uiReducer
	);

	const configFilters = [
		{
			name: "id",
			elementType: "input"
		},
		{
			name: "billingEmail",
			elementType: "input"
		},

		{
			name: "maxMonthlyCuFrom",
			elementType: "input"
		},
		{
			name: "maxMonthlyCuTo",
			elementType: "input"
		},

		{
			name: "maxTestCuFrom",
			elementType: "input"
		},
		{
			name: "maxTestCuTo",
			elementType: "input"
		},

		{
			name: "activationFrom",
			elementType: "date"
		},
		{
			name: "activationTo",
			elementType: "date"
		},

		{
			name: "maxParticipantCu",
			elementType: "multiselect",
			label: "Max Participant CU",
			options: [
				...classificators.compute_unit.map(item => ({
					label: item.name,
					key: item.value
				}))
			],
			selected: ""
		},
		{
			name: "maxDuration",
			elementType: "multiselect",
			label: "Max Duration",
			options: [
				...classificators.test_duration.map(item => ({
					label: item.name,
					key: item.value
				}))
			],
			selected: ""
		},
		{
			name: "paymentPlan",
			elementType: "multiselect",
			label: "Payment plan",
			options: [
				{ label: "Enterprise", key: "enterprise" },
				{ label: "Monthly", key: "monthly" },
				{ label: "PAYG: Essential", key: "single_basic" },
				{ label: "PAYG: Ultimate", key: "single_pro" },
				{ label: "Yearly", key: "yearly" }
			],
			selected: ""
		},
		{
			name: "paymentStatus",
			elementType: "multiselect",
			label: "Payment status",
			options: [
				{ label: "Cancelled", key: "cancelled" },
				{ label: "Declined", key: "declined" },
				{ label: "Draft", key: "draft" },
				{ label: "Processing", key: "processing" },
				{ label: "Skip renewal", key: "skip_renewal" },
				{ label: "Success", key: "success" },
				{ label: "Vat invalid", key: "vat_invalid" }
			],
			selected: ""
		},
		{
			name: "mosEnabled",
			elementType: "radio",
			label: "Mos enabled",
			options: [
				{ label: "Yes", key: "true" },
				{ label: "No", key: "false" },
				{ label: "All", key: "" }
			],
			selected: ""
		}
	];

	const { PROJECTS } = columnNames;

	const routeProjects = createRoute(true, routes.PROJECTS);

	const linkColumns = [{ name: PROJECTS, route: routeProjects }];

	const {
		columnFilters,
		colNames,
		handleFetchData,
		init,
		onPageChange,
		pager,
		setColumnFilters,
		setDataFilters
	} = useAutoFillTableLogic(
		[],
		[],
		getAllSubscriptions,
		getSubscription,
		{} as { key: string; columns: string[] },
		[],
		tableName.SUBSCRIPTIONS,
		[],
		linkColumns
	);

	return (
		<div className="subscriptions">
			<h1>Subscriptions</h1>

			<AutoFillTable
				columnFilters={columnFilters}
				tableColumnNames={colNames}
				data={subscriptionData}
				filters={configFilters as ConfigFilters[]}
				handleFetchData={handleFetchData}
				init={init}
				loading={isFetching}
				nestedData={{} as { key: string; columns: string[] }}
				onPageChange={onPageChange}
				pager={pager}
				setDataFilters={setDataFilters}
				setColumnFilters={setColumnFilters}
				tableName={tableName.SUBSCRIPTIONS}
				linkColumns={linkColumns}
			/>
		</div>
	);
};

export default Subscriptions;
