import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import crud from "../../api/crud";

import { queryKeys } from "../../constants";

import { routes } from "../../router/routes";

import {
	APIError,
	RejectWithValue,
	ResponseData,
	ResponseDataWithPaging,
	getAllParams,
	getByIDParams,
	getCustomResourceParams
} from "../../types/api";
import { Project } from "../../types/project";

import {
	appendSearchQuery,
	createQuery,
	createRoute,
	getOffset,
	paginationFallback,
	setQueryParam
} from "../../utils/request";

interface IProjectReducer {
	projectData: ResponseDataWithPaging | ResponseData | null;
	isFetching: boolean;
	errorMessage: APIError | string | null;
}

const initialState: IProjectReducer = {
	projectData: null,
	isFetching: false,
	errorMessage: null
};

const {
	ID,
	FILTER: {
		PROJECT: {
			ACCOUNT_ID,
			HAS_CUSTOM_AWS,
			HAS_SUBSCRIPTION,
			LANGUAGE,
			NAME,
			SUBSCRIPTION_ID
		}
	},
	LIMIT,
	OFFSET,
	PAGE
} = queryKeys;

export const getAllProjects = createAsyncThunk(
	"projects/getAllProjects",
	async (
		params: getAllParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, queryParams } = params;
		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const { name, language, hasSubscription, hasAwsInfo } = filters || {};

		const searchQuery = createQuery(
			setQueryParam(HAS_CUSTOM_AWS, hasAwsInfo),
			setQueryParam(HAS_SUBSCRIPTION, hasSubscription),
			setQueryParam(LANGUAGE, language),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(PAGE, page)
		);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(true, routes.V1ADMIN, routes.PROJECTS);

		route += createQuery(
			setQueryParam(HAS_CUSTOM_AWS, hasAwsInfo),
			setQueryParam(HAS_SUBSCRIPTION, hasSubscription),
			setQueryParam(LANGUAGE, language),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(OFFSET, getOffset(limit, page || 1))
		);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAllProjects
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getProject = createAsyncThunk(
	"projects/getProject",
	async (
		params: getByIDParams,
		{ rejectWithValue }
	): Promise<Project | RejectWithValue> => {
		const { navigate, location, id } = params;

		const searchQuery = createQuery(
			setQueryParam(ID, Number(id)),
			setQueryParam(queryKeys.PAGE, 1),
			setQueryParam(queryKeys.LIMIT, 20)
		);
		appendSearchQuery(navigate, location, searchQuery);

		const route = createRoute(true, routes.V1ADMIN, routes.PROJECTS, id);
		const options = { suppressNotification: true };

		try {
			const response = await crud.READ<Project>(navigate, route, options);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getAccountProjects = createAsyncThunk(
	"projects/getAccountProjects",
	async (
		params: getCustomResourceParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, id, queryParams } = params;
		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const { name, language, hasSubscription, hasAwsInfo } = filters || {};

		const searchQuery = createQuery(
			setQueryParam(ACCOUNT_ID, id),
			setQueryParam(HAS_CUSTOM_AWS, hasAwsInfo),
			setQueryParam(HAS_SUBSCRIPTION, hasSubscription),
			setQueryParam(LANGUAGE, language),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(PAGE, page)
		);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(
			true,
			routes.V1ADMIN,
			routes.ACCOUNTS,
			id,
			routes.PROJECTS
		);

		route += createQuery(
			setQueryParam(HAS_CUSTOM_AWS, hasAwsInfo),
			setQueryParam(HAS_SUBSCRIPTION, hasSubscription),
			setQueryParam(LANGUAGE, language),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(OFFSET, getOffset(limit, page || 1))
		);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getAccountProjects
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getSubscriptionProjects = createAsyncThunk(
	"projects/getSubscriptionProjects",
	async (
		params: getCustomResourceParams,
		{ rejectWithValue }
	): Promise<ResponseDataWithPaging | RejectWithValue> => {
		const { navigate, location, id, queryParams } = params;
		const { filters, pagination } = queryParams || {};
		const { limit, page } = pagination || {};
		const { name, language, hasSubscription, hasAwsInfo } = filters || {};

		const searchQuery = createQuery(
			setQueryParam(SUBSCRIPTION_ID, id),
			setQueryParam(HAS_CUSTOM_AWS, hasAwsInfo),
			setQueryParam(HAS_SUBSCRIPTION, hasSubscription),
			setQueryParam(LANGUAGE, language),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),

			setQueryParam(PAGE, page)
		);

		appendSearchQuery(navigate, location, searchQuery);

		let route = createRoute(
			true,
			routes.V1ADMIN,
			routes.SUBSCRIPTIONS,
			id,
			routes.PROJECTS
		);

		route += createQuery(
			setQueryParam(HAS_CUSTOM_AWS, hasAwsInfo),
			setQueryParam(HAS_SUBSCRIPTION, hasSubscription),
			setQueryParam(LANGUAGE, language),
			setQueryParam(LIMIT, limit),
			setQueryParam(NAME, name),
			setQueryParam(OFFSET, getOffset(limit, page || 1))
		);

		try {
			const response = await crud.READ<ResponseDataWithPaging>(navigate, route);

			if (
				queryParams &&
				response?.pagination?.page === 0 &&
				response?.pagination.total_pages > 0
			) {
				return paginationFallback(
					navigate,
					location,
					queryParams,
					pagination,
					getSubscriptionProjects
				);
			}

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const projectsSlice = createSlice({
	name: "projects",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAllProjects.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getAllProjects.fulfilled, (state, action) => {
				state.projectData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getAllProjects.rejected, (state, action) => {
				state.projectData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getProject.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getProject.fulfilled, (state, action) => {
				state.projectData = { results: [action.payload as Project] };
				state.isFetching = false;
			})
			.addCase(getProject.rejected, (state, action) => {
				state.projectData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getAccountProjects.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getAccountProjects.fulfilled, (state, action) => {
				state.projectData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getAccountProjects.rejected, (state, action) => {
				state.projectData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			})
			.addCase(getSubscriptionProjects.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(getSubscriptionProjects.fulfilled, (state, action) => {
				state.projectData = action.payload as ResponseDataWithPaging;
				state.isFetching = false;
			})
			.addCase(getSubscriptionProjects.rejected, (state, action) => {
				state.projectData = { results: [] };
				state.errorMessage = action.payload as APIError;
				state.isFetching = false;
			});
	}
});

export default projectsSlice.reducer;
