import { FC } from "react";

const Footer: FC = () => {
	return (
		<div className="footer">
			<div className="footer__app-version">
				{`Admin ${process.env.REACT_APP_VERSION}`}
			</div>
		</div>
	);
};

export default Footer;
