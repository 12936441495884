import { FC } from "react";

import { icons } from "../../assets/media/svg";

import { SVGIcon } from "ui-kit";

interface IProps {
	className: string;
	dataFor?: string;
	dataTip?: string;
	height?: number;
	icon: keyof typeof icons;
	onClick?(
		e?: React.MouseEvent<HTMLElement, MouseEvent> | MouseEvent | TouchEvent
	): void;
	width?: number;
}

const Icon: FC<IProps> = ({
	className = "",
	dataFor = "",
	dataTip = "",
	height,
	icon,
	onClick,
	width
}): JSX.Element => {
	const newProps = {
		"data-for": dataFor,
		"data-tip": dataTip,
		className: className || `${icon}-icon`,
		height,
		onClick,
		width
	};

	return <SVGIcon customIconSet={icons} iconName={icon} {...newProps} />;
};

export default Icon;
