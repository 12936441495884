import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { alertError } from "../api/Api";

import { browserActions, limitOptions, queryKeys } from "../constants";

import { AppDispatch } from "../redux/store";
import { IStoreState } from "../redux/slices";

import { Account } from "../types/account";
import { APIError, ResponseDataWithPaging } from "../types/api";
import { AppliedFilters, ColumnFilters } from "../types/filters";
import { PagerData } from "../types/pagination";
import { Project } from "../types/project";

import {
	arrayNotEmpty,
	initializeKeys,
	objectEmpty,
	snakeToCamel
} from "../utils/method";
import { getQueryParam, getStateFromQuery } from "../utils/request";

/**
 * Data transfer object
 */
type DTO = Account | Project;

type IProps = {
	columnFilters: ColumnFilters;
	colNames: string[];
	handleFetchData: (pagination: PagerData, dFilters: AppliedFilters) => void;
	init: boolean;
	onPageChange: (currentPage: number, itemsPerPage: number) => void;
	pager: PagerData;
	setColumnFilters: (cf: ColumnFilters) => void;
	setDataFilters: (df: AppliedFilters) => void;
};

const useAutoFillTableLogic = (
	customColumns: string[],
	disabledColumns: string[],
	getAll: Function,
	getByID: Function,
	nestedData: { key: string; columns: string[] },
	suppressColumns: string[],
	tableName: string,
	qpResolver: { paramMatch: string; fn: Function }[],
	linkColumns?: {
		name: string;
		route: string;
	}[]
): IProps => {
	const navigate = useNavigate();
	const navigationType = useNavigationType();
	const location = useLocation();
	const dispatch: AppDispatch = useDispatch();

	const [dataFilters, setDataFilters] = useState<AppliedFilters>(
		getStateFromQuery(location)
	);

	const [init, setInit] = useState<boolean>(false);

	const [columnFilters, setColumnFilters] = useState<ColumnFilters>({});
	const [colNames, setColNames] = useState<string[]>([]);

	const paramsRef = useRef(location.search);

	const columnConfig = useSelector(
		({ uiReducer }: IStoreState) => uiReducer.columnConfig
	);

	const [pager, setPager] = useState<PagerData>({
		limit: limitOptions.some(
			option =>
				option.value === Number(getQueryParam(location, queryKeys.LIMIT))
		)
			? Number(getQueryParam(location, queryKeys.LIMIT))
			: 20,
		page: Number(getQueryParam(location, queryKeys.PAGE)) || 1,
		total_pages: 1,
		total_items: 0
	});

	useEffect(() => {
		handleFetchData(
			{
				...pager,
				page: Number(getQueryParam(location, queryKeys.PAGE))
			},
			dataFilters
		);
	}, []);

	useEffect(() => {
		if (
			paramsRef.current === location.search ||
			navigationType !== browserActions.POP
		) {
			return;
		}

		handleFetchData(
			{
				...pager,
				page: Number(getQueryParam(location, queryKeys.PAGE))
			},
			dataFilters
		);
	}, [location.search]);

	useEffect(() => {
		applyColumnFilters();
	}, [columnFilters]);

	const initColumnFilters = (reqResponse: DTO): void => {
		const autoColumns = Object.keys(reqResponse);

		let columns = [
			...autoColumns,
			...customColumns,
			...(nestedData?.columns || [])
		].filter(column => !suppressColumns.includes(column));

		if (linkColumns) {
			const linkColNames = linkColumns.map(lc => lc.name);

			columns = [...columns, ...linkColNames];
		}

		const uniqueColumns = columns.filter(function (item, pos, self) {
			return self.indexOf(item) == pos;
		});

		setColNames(uniqueColumns);

		setColumnFilters(
			objectEmpty(columnConfig[tableName] || {})
				? initializeKeys(columns, disabledColumns)
				: columnConfig[tableName]
		);
	};

	const handleFetchData = (
		pagination: PagerData,
		dFilters: AppliedFilters
	): void => {
		setDataFilters(dFilters);

		const paging = pagination || pager;

		const searchParams = dFilters;
		const queryParams = {
			pagination: paging,
			filters: dFilters
		};

		for (const qpr of qpResolver) {
			const formattedPM = snakeToCamel(qpr.paramMatch);

			if (!searchParams[formattedPM]) continue;

			dispatch(
				qpr.fn({
					navigate,
					location,
					id: searchParams[formattedPM],
					queryParams
				})
			)
				.unwrap()
				.then(
					(response: ResponseDataWithPaging) => {
						const { results, pagination } = response;

						if (!arrayNotEmpty(results)) return;

						setPager(pagination);

						// Dynamically build column filters
						// based on first object of read-all response
						initColumnFilters(results[0] as DTO);
					},
					(error: APIError) => alertError(error)
				)
				.then(() => setInit(true));

			return;
		}

		if (dFilters && "id" in dFilters && dFilters.id !== "") {
			dispatch(getByID({ navigate, location, id: dFilters.id }))
				.unwrap()
				.then(
					(response: DTO) => {
						setPager({ ...pager, total_pages: 1, page: 1 });

						if (response) {
							// Dynamically build column filters
							// based on single object response
							initColumnFilters(response);
						}
					},
					() => {}
				)
				.then(() => setInit(true));

			return;
		}

		dispatch(getAll({ navigate, location, queryParams }))
			.unwrap()
			.then(
				(response: ResponseDataWithPaging) => {
					const { results, pagination } = response;

					setPager(pagination);

					if (objectEmpty(columnFilters) && arrayNotEmpty(results)) {
						initColumnFilters(results[0] as DTO);
					}
				},
				() => {}
			)
			.then(() => setInit(true));
	};

	const onPageChange = (currentPage: number, itemsPerPage: number): void => {
		handleFetchData(
			{ ...pager, page: currentPage, limit: itemsPerPage },
			dataFilters
		);
		setPager({ ...pager, limit: itemsPerPage });
	};

	const applyColumnFilters = (): void => {
		const filteredColumns = Object.keys(columnFilters).filter(
			f => columnFilters[f as keyof typeof columnFilters]
		);

		setColNames(filteredColumns);
	};

	return {
		columnFilters,
		colNames,
		handleFetchData,
		init,
		onPageChange,
		pager,
		setColumnFilters,
		setDataFilters
	};
};

export { useAutoFillTableLogic };
